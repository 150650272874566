import React from "react";

const PasswordRecovery = ({ setLoginsuccess }) => {
  setLoginsuccess(true);
  return (
    <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing login">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content login">
              <h1 class="signin">Password Recovery</h1>
              <p class="signin">
                Enter your email and instructions will be sent to you!
              </p>
              <form class="text-left">
                <div class="form">
                  <div id="email-field" class="field-wrapper input">
                    <div class="d-flex justify-content-between">
                      <label for="email">EMAIL</label>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-at-sign"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                    </svg>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      class="form-control"
                      value=""
                      placeholder="Email"
                    />
                  </div>

                  <div class="d-sm-flex justify-content-between">
                    <div class="field-wrapper">
                      <button
                        type="submit"
                        className="btn submit-btn mr-2"
                        value=""
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
