import { createSelector } from "reselect";

const loginUserData = (state) => {
  return state.userLoginReducer.loginUserData;
};

export const userDataSelector = createSelector(
  loginUserData,
  (loginUserData) => loginUserData
);
