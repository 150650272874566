import React, { useEffect } from "react";
import { downloadHidKeyApi } from "../../../../Service/License";

const License = () => {
  // useEffect(() => {
  //   const webSocket = new WebSocket();
  // }, []);

  const handleDownload = () => {
    downloadHidKeyApi()
      .then((response) => {
        let fileName = "streama.hid";
        console.log(JSON.stringify(response));
        let hidKey = response?.data?.data[0]?.hidKey;
        console.log(JSON.stringify(hidKey));

        fileDownload(fileName, hidKey);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  };

  const fileDownload = (fileName, hidKey) => {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(hidKey)
    );
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
  };

  return (
    <>
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row layout-top-spacing" id="cancel-row">
            <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div class="widget-content widget-content-area br-6">
                {/* ---------- Header Region --------------- */}
                <div className="row">
                  <div class="col-xl-8 col-md-8 col-sm-12 col-8 mb-3">
                    <h3 class="content-header">License</h3>
                  </div>
                  <div class="col-xl-3 col-md-3 col-sm-6 col-6 mb-3 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-custom mb-3 mr-3"
                      style={{ backgroundColor: "#3c3c3c", color: "#e4d9d3" }}
                      onClick={handleDownload}
                    >
                      Download Key
                    </button>
                  </div>
                  <div class="col-xl-1 col-md-1 col-sm-6 col-6 mb-3">
                    <span title="Create Admin">
                      <a
                        type="button"
                        style={{ backgroundColor: "#3c3c3c" }}
                        className="btn btn-custom mb-3 mr-2 rounded-circle"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        data-whatever="@mdo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                          // onClick={() => handleCreate()}
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                      </a>
                    </span>
                  </div>
                </div>

                {/* ----------- Modal Content ------------------ */}

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          New License
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form>
                          <div class="form-group">
                            <label for="recipient-name" class="col-form-label">
                              File:
                            </label>
                            <input
                              type="file"
                              class="form-control"
                              id="recipient-name"
                              style={{ backgroundColor: "#faf7f6" }}
                            />
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn submit-btn mr-2">
                          Save
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------------- Breadcrumb Region ------------- */}
                <nav className="breadcrumb-one" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/dash">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                            fill="#3c3c3c"
                          />
                        </svg>
                      </a>
                    </li>

                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">Settings - License</a>
                    </li>
                    {/* <li class="breadcrumb-item active" aria-current="page">
                      <span>UI Kit</span>
                    </li> */}
                  </ol>
                </nav>

                {/* ----------------- Main --------------------- */}
                <div
                  class="table-responsive mb-4 mt-4"
                  style={{ overflowX: "scroll" }}
                >
                  <div
                    id="zero-config_wrapper"
                    class="dataTables_wrapper container-fluid dt-bootstrap4"
                  >
                    <table
                      id="zero-config"
                      class="table table-hover dataTable"
                      style={{
                        // width: "100%",
                        // role: "grid",
                        // ariaDescribedby: "zero-config_info",
                        border: " 1px solid #cccccc",
                        borderCollapse: " collapse",
                      }}
                    >
                      <thead className="table-theader">
                        <tr role="row">
                          <th
                            className="table-thead-tr"
                            style={{ border: "1px solid black", width: "50%" }}
                          >
                            Key
                          </th>
                          <th
                            className="table-thead-tr"
                            style={{ border: "1px solid black", width: "50%" }}
                          >
                            Values
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row">
                          <td className="table-tbody-tr">Version</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.version} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">Start Date</td>
                          <td className="table-tbody-tr">
                            {" "}
                            {/* {LICENCE_DATA?.GS_JOB_LICENSE_START_DATE} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">Expiry date</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.GS_JOB_LICENSE_EXPIRY_DATE} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">Account Type</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.account_type} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">license type</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.li_type} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">license status</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.li_status} */}
                          </td>
                        </tr>

                        <tr>
                          <td className="table-tbody-tr">
                            Number of Subscribers
                          </td>
                          <td className="table-tbody-tr">
                            {" "}
                            {/* {LICENCE_DATA?.gs_max_num_subscriber} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">
                            {" "}
                            Number of Concurrenet Users
                          </td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.gs_max_num_concurrent_user} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">Number of assets</td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.gs_max_num_asset} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">
                            Number of Assetgroups
                          </td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.gs_max_num_assetgroup} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-tbody-tr">
                            Number of UserGroups
                          </td>
                          <td className="table-tbody-tr">
                            {/* {LICENCE_DATA?.gs_max_num_usergroup} */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default License;
