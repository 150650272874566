import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "../Global.css";

export const Dashboard = () => {
  // function Items({ currentItems }) {
  //   return (
  //     <>
  //       {currentItems &&
  //         currentItems.map((item) => (
  //           <div key={item.id}>
  //             {list?.data?.map((a) => (
  //               <h3>{item.id}</h3>
  //             ))}
  //           </div>
  //         ))}
  //     </>
  //   );
  // }

  const [iconClicked, setIconClicked] = useState(false);

  return (
    <>
      {/* {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )} */}
      {/* {!loading && ( */}

      {/* </div> */}
      {/* <div class="footer-wrapper">
              <div class="footer-section f-section-1">
                <p class="">
                  Copyright © 2020{" "}
                  <a target="_blank" href="https://designreset.com">
                    DesignReset
                  </a>
                  , All rights reserved.
                </p>
              </div>
              <div class="footer-section f-section-2">
                <p class="">
                  Coded with{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-heart"
                  >
                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                  </svg>
                </p>
              </div>
            </div> */}
      {/* </div> */}
      {/* )} */}
    </>
  );
};
