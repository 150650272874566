import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import "../Streams/Streams.css";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  deleteLiveStreamApi,
  getAllLiveStreamApi,
} from "../../../Service/LiveStream";
import { userDataSelector } from "../../../Redux/Login/Selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import VideoPlayer from "../../VideoPlayer";
import VideoPlayerDrm from "../VideoPlayerDrm";

const Streams = ({ LOGIN_USER_DATA }) => {
  const userID = LOGIN_USER_DATA?.data?.data[0]?._id;
  const userName = LOGIN_USER_DATA?.data?.data[0]?.userName;
  const source1 = "http://rt-glb.rttv.com/live/rtnews/playlist_800Kb.m3u8";
  const type1 = "application/x-mpegURL";

  const navigate = useNavigate();
  const [jobList, setJobList] = useState();
  const [casedata, setCasedata] = useState();
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const handleSetDataPerPage = (data) => {
    setItemsPerPage(data);
  };
  const [inputIssue, setInputIssue] = useState(false);
  const [jobStarted, setJobStarted] = useState(false);
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState({
    hls: "",
    dash: "",
  });
  const [source, setSource] = useState("");
  const [type, setType] = useState("");
  const [searchData, setSearchData] = useState();
  const [drmKey, setDrmKey] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getAllLiveStreamApi(userName)
      .then((response) => {
        setJobList(response?.data?.data);
        setSearchData(response?.data?.data);
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }, []);

  const endOffset = itemOffset + itemsPerPage;

  const currentItems =
    jobList != null &&
    jobList != undefined &&
    jobList?.length != null &&
    jobList?.length != undefined &&
    jobList?.length > 0 &&
    jobList?.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(jobList?.length / itemsPerPage);
  useEffect(() => {
    pageCount = Math.ceil(jobList?.length / itemsPerPage);
  }, [jobList]);

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((item) => (
            <div key={item.id}>
              {jobList?.data?.map((a) => (
                <h3>{item.id}</h3>
              ))}
            </div>
          ))}
      </>
    );
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % jobList?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const handleSort = (columnName) => {
    let direction = "asc";
    if (sortedColumn === columnName && sortDirection === "asc") {
      direction = "desc";
    }
    setSortDirection(direction);
    setSortedColumn(columnName);

    // const sortedData = [...caseList].sort((a, b) => {
    //   const valueA = a && a[columnName];
    //   const valueB = b && b[columnName];

    //   // Check if valueA and valueB are defined
    //   if (valueA === undefined || valueB === undefined) {
    //     return 0; // or handle this case as you see fit
    //   }

    //   const isNumber = !isNaN(valueA) && !isNaN(valueB);

    //   if (direction === "asc") {
    //     if (isNumber) {
    //       return parseFloat(valueA) - parseFloat(valueB);
    //     } else {
    //       return valueA.localeCompare(valueB);
    //     }
    //   } else {
    //     if (isNumber) {
    //       return parseFloat(valueB) - parseFloat(valueA);
    //     } else {
    //       return valueB.localeCompare(valueA);
    //     }
    //   }
    // });

    // setCaselist(sortedData);
  };

  const searchFunction = (data) => {
    var lowSearch = data.toLowerCase();
    let filteredData = searchData?.filter((wine) =>
      Object.values(wine).some((val) =>
        String(val).toLowerCase().includes(lowSearch)
      )
    );
    setJobList(filteredData);
    setItemOffset(0);
  };

  const handleCreate = () => {
    navigate("/createstream");
  };

  const handleEditStream = (item) => {
    console.log("LLLLLL", item);
    navigate("/editstream", {
      state: {
        item: item,
      },
    });
  };

  const handleJobStartStop = (e) => {
    jobStarted === true ? setJobStarted(false) : setJobStarted(true);
  };

  const handleModalData = (item) => {
    console.log("ITEM", JSON.stringify(item));
    if (item?.isDRM === 1) {
      setDrmKey(
        "https://fpe.ezdrm.com/proxyEngine.aspx?auth=eyJwWCI6IkIzMkU2OCIsImNsaWVudF9pcCI6IjEwLjAuOTAuNDEiLCJjYW5fcGxheSI6IlRydWUifQ=="
      );
    } else {
      setDrmKey("");
    }
    setPreviewUrl({
      hls: item?.output_urls[0],
      dash: item?.output_urls[1],
    });
  };

  const handlePreview = (videoType) => {
    if (videoType === "hls") {
      setSource(previewUrl.hls);
      setType("application/x-mpegURL");
    } else if (videoType === "dash") {
      setSource(previewUrl.dash);
      setType("application/dash+xml");
    }
  };

  useEffect(() => {
    if (show) {
      setSource("");
      setType("");
    }
  }, [show]);

  const handleDelete = (item) => {
    let deleteItem = {
      id: item._id,
      userId: userID,
    };

    if (item && item?._id) {
      Swal.fire({
        html:
          '<div style="display: flex; align-items: center;">' +
          '<div style="margin-right: 10px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path> <line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> </div>' +
          "<span>Are you sure you want to delete?</span>" +
          "</div>",
        showCancelButton: true,
        background: "black",
        color: "white",
        position: "top-right",
        customClass: "swal-width",
        confirmButtonColor: "#3c3c3c",
        cancelButtonColor: "#E7515A",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteLiveStreamApi(deleteItem)
            .then((response) => {
              if (response?.data?.status === true) {
                toast.success("Deleted Successfully!");
                window.location.reload();
              }
            })
            .catch((error) => {
              console.error("Error deleting :", error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while deleting the item!",
                background: "rgb(15 1 35)",
              });
            });
        }
      });
    } else {
      // Handle case where 'a' or 'a.id' is undefined
      console.error("Invalid parameter 'a' or 'a.id' is undefined.");
      // Optionally show an error message to the user
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid parameter or 'id' is undefined.",
        background: "rgb(15 1 35)",
      });
    }
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing" id="cancel-row">
          <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div
              class="widget-content widget-content-area br-6"
              // style={{ backgroundColor: "#FBF5FF" }}
            >
              {/* ----------------- Header area --------------- */}
              <div className="row">
                <div class="col-xl-8 col-md-8 col-sm-12 col-12 mb-3">
                  <h3 class="content-header">Streams</h3>
                </div>
                <div class="col-xl-3 col-md-3 col-sm-8 col-8 mb-4 d-md-flex justify-content-md-end">
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled button group"
                    className="mui-group"
                  >
                    <Button className="mui-buttongroup" title="Import">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.625 12.375C0.970178 12.375 1.25 12.6549 1.25 13V16.125C1.25 16.8154 1.80964 17.375 2.5 17.375H17.5C18.1904 17.375 18.75 16.8154 18.75 16.125V13C18.75 12.6549 19.0298 12.375 19.375 12.375C19.7202 12.375 20 12.6549 20 13V16.125C20 17.5057 18.8807 18.625 17.5 18.625H2.5C1.11929 18.625 0 17.5057 0 16.125V13C0 12.6549 0.279822 12.375 0.625 12.375Z"
                          fill="#E4D9D3"
                        />
                        <path
                          d="M9.55806 14.8169C9.80214 15.061 10.1979 15.061 10.4419 14.8169L14.1919 11.0669C14.436 10.8229 14.436 10.4271 14.1919 10.1831C13.9479 9.93898 13.5521 9.93898 13.3081 10.1831L10.625 12.8661V1.875C10.625 1.52982 10.3452 1.25 10 1.25C9.65482 1.25 9.375 1.52982 9.375 1.875V12.8661L6.69194 10.1831C6.44786 9.93898 6.05214 9.93898 5.80806 10.1831C5.56398 10.4271 5.56398 10.8229 5.80806 11.0669L9.55806 14.8169Z"
                          fill="#E4D9D3"
                        />
                      </svg>
                    </Button>
                    <Button className="mui-buttongroup" title="Export">
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.625 12.9643C0.970178 12.9643 1.25 13.2841 1.25 13.6786V17.25C1.25 18.039 1.80964 18.6786 2.5 18.6786H17.5C18.1904 18.6786 18.75 18.039 18.75 17.25V13.6786C18.75 13.2841 19.0298 12.9643 19.375 12.9643C19.7202 12.9643 20 13.2841 20 13.6786V17.25C20 18.828 18.8807 20.1072 17.5 20.1072H2.5C1.11929 20.1072 0 18.828 0 17.25V13.6786C0 13.2841 0.279822 12.9643 0.625 12.9643Z"
                          fill="#E4D9D3"
                        />
                        <path
                          d="M9.55806 0.459209C9.80214 0.180264 10.1979 0.180264 10.4419 0.459209L14.1919 4.74492C14.436 5.02387 14.436 5.47613 14.1919 5.75508C13.9479 6.03402 13.5521 6.03402 13.3081 5.75508L10.625 2.68872V15.25C10.625 15.6445 10.3452 15.9643 10 15.9643C9.65482 15.9643 9.375 15.6445 9.375 15.25V2.68872L6.69194 5.75508C6.44786 6.03402 6.05214 6.03402 5.80806 5.75508C5.56398 5.47613 5.56398 5.02387 5.80806 4.74492L9.55806 0.459209Z"
                          fill="#E4D9D3"
                        />
                      </svg>
                    </Button>
                  </ButtonGroup>
                </div>
                <div class="col-xl-1 col-md-1 col-sm-4 col-4 mb-3 d-flex justify-content-end">
                  <span title="Create Live Stream">
                    <a
                      type="button"
                      style={{ backgroundColor: "#3c3c3c" }}
                      className="btn btn-custom mb-2 mr-2 rounded-circle"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus"
                        onClick={() => handleCreate()}
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </a>
                  </span>
                </div>
              </div>

              {/* ------------------ Breadcrumb area ------------------ */}
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dash">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                          fill="#3c3c3c"
                        />
                      </svg>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Streams</a>
                  </li>
                  {/* <li class="breadcrumb-item active" aria-current="page">
                      <span>UI Kit</span>
                    </li> */}
                </ol>
              </nav>

              {/* ------------------- Main area --------------- */}
              <div
                class="table-responsive mb-4 mt-4"
                style={{ overflowX: "scroll" }}
              >
                <div
                  id="zero-config_wrapper"
                  class="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div class="tab-content mb-4" id="border-tabsContent">
                    <div
                      class="tab-pane fade show active"
                      id="border-home"
                      role="tabpanel"
                      aria-labelledby="border-home-tab"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-6 d-flex">
                          <div
                            class="dataTables_length"
                            id="zero-config_length"
                          >
                            <label className="select-search-labels">
                              Results :{" "}
                              <select
                                name="zero-config_length"
                                aria-controls="zero-config"
                                class="form-control select-table-size"
                                onChange={(e) =>
                                  handleSetDataPerPage(e.target.value)
                                }
                              >
                                <option value="7">7</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex d-md-block">
                          <div
                            id="zero-config_filter"
                            class="dataTables_filter"
                          >
                            <label className="select-search-labels">
                              Search:{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-search"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                              <input
                                type="search"
                                class="form-control select-table-size"
                                placeholder="Search..."
                                aria-controls="zero-config"
                                onChange={(e) => searchFunction(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12">
                          <table
                            id="zero-config"
                            class="table table-hover dataTable"
                            style={{
                              // width: "100%",
                              // role: "grid",
                              // ariaDescribedby: "zero-config_info",
                              border: " 1px solid #cccccc",
                              borderCollapse: " collapse",
                            }}
                          >
                            <thead className="table-theader">
                              <tr role="row">
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Streams
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  input
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  DVR
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  DRM
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Output
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.length > 0 &&
                                currentItems?.map((item) => {
                                  return (
                                    <tr role="row" key={item?.id}>
                                      <td className="table-tbody-tr">
                                        <span
                                          className="job_name"
                                          title="Edit Stream"
                                          style={{
                                            // borderBottom: "1px solid #3c3c3c",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleEditStream(item)}
                                        >
                                          {item?.stream_name}
                                        </span>
                                        {/* {jobStarted ? (
                                          <div
                                            style={{
                                              color: "#6DC771",
                                              fontSize: "12px",
                                              fontWeight: "inherit",
                                            }}
                                          >
                                            Online
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              color: "#E7515A",
                                              fontSize: "12px",
                                              fontWeight: "inherit",
                                            }}
                                          >
                                            Offline
                                          </div>
                                        )} */}
                                      </td>
                                      <td className="table-tbody-tr">
                                        <span className="text-left">
                                          {inputIssue ? (
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                cx="8"
                                                cy="8"
                                                r="8"
                                                fill="#E7515A"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                cx="8"
                                                cy="8"
                                                r="8"
                                                fill="#6DC771"
                                              />
                                            </svg>
                                          )}
                                        </span>
                                        <span
                                          className="truncate-text"
                                          title={item?.urls}
                                        >
                                          {item?.urls}
                                        </span>
                                      </td>
                                      <td className="table-tbody-tr">
                                        {item?.dvr_path
                                          ? item?.dvr_path
                                          : "Disabled"}
                                      </td>
                                      <td className="table-tbody-tr">
                                        {item?.isDRM == 1
                                          ? "Enabled"
                                          : "Disabled"}
                                      </td>
                                      <td className="table-tbody-tr">
                                        {/* {jobStarted ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#3c3c3c"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-square"
                                            style={{
                                              marginRight: "5px",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={(e) =>
                                              handleJobStartStop(e)
                                            }
                                          >
                                            <rect
                                              x="3"
                                              y="3"
                                              width="18"
                                              height="18"
                                              rx="2"
                                              ry="2"
                                            ></rect>
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#3c3c3c"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-play"
                                            style={{
                                              marginRight: "5px",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={(e) =>
                                              handleJobStartStop(e)
                                            }
                                          >
                                            <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                          </svg>
                                        )} */}

                                        {/* <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#3c3c3c"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="feather feather-refresh-cw"
                                          style={{
                                            marginRight: "5px",
                                            stroke: "#3c3c3c",
                                          }}
                                        >
                                          <polyline points="23 4 23 10 17 10"></polyline>
                                          <polyline points="1 20 1 14 7 14"></polyline>
                                          <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                                        </svg> */}

                                        <span title="Delete Live Stream">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trash-2"
                                            title="DeleteSmtp"
                                            style={{
                                              marginRight: "5px",
                                              cursor: "pointer",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={() => handleDelete(item)}
                                          >
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line
                                              x1="10"
                                              y1="11"
                                              x2="10"
                                              y2="17"
                                            ></line>
                                            <line
                                              x1="14"
                                              y1="11"
                                              x2="14"
                                              y2="17"
                                            ></line>
                                          </svg>
                                        </span>

                                        <span title="Output Overview">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-tv"
                                            style={{
                                              marginRight: "5px",
                                              cursor: "pointer",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={() => {
                                              handleShow();
                                              handleModalData(item);
                                            }}
                                          >
                                            <rect
                                              x="2"
                                              y="7"
                                              width="20"
                                              height="15"
                                              rx="2"
                                              ry="2"
                                            ></rect>
                                            <polyline points="17 2 12 7 7 2"></polyline>
                                          </svg>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                <button
                                  type="submit"
                                  className="btn submit-btn mr-2"
                                  onClick={(e) => {
                                    handlePreview("hls");
                                  }}
                                >
                                  HLS
                                </button>
                                <button
                                  type="submit"
                                  className="btn submit-btn mr-2"
                                  onClick={(e) => {
                                    handlePreview("dash");
                                  }}
                                >
                                  DASH
                                </button>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <VideoPlayerDrm
                                source={source}
                                type={type}
                                drmKey={drmKey}
                              />
                              <div>{source}</div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                              <Button variant="primary" onClick={handleClose}>
                                Save Changes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            {jobList?.length == 0 && (
                              <paragraph>No matching records found</paragraph>
                            )}
                          </div>
                          <Items currentItems={currentItems} />

                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={15}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-7">
                          <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="zero-config_paginate"
                          ></div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LOGIN_USER_DATA: userDataSelector,
});

export default connect(mapStateToProps)(Streams);
