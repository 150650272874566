import React from "react";
import { css } from "@emotion/react";
import "./loading.css";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const LoadingPage = () => {
  return (
    // <div
    //   style={{
    //     alignItems: "center",
    //     justifyContent: "center",
    //     display: "flex",
    //     width: "100%",
    //     height: "100%",
    //     padding: "100px",
    //   }}
    // >
    //   <FadeLoader color="white" loading={true} css={override} size={150} />
    // </div>
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        width: "100%",
        height: "100vh",
        // marginTop: "20%",
      }}
    >
      <div className="loading">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default LoadingPage;
