import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Icon } from "react-icons-kit";
import "../Global.css";
import * as USER_LOGIN_ACTION from "../../../Redux/Login/Action";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = ({ setLoginsuccess }) => {
  useEffect(() => {
    localStorage.removeItem("isAuthenticated");
    setLoginsuccess(true);
  }, []);
  //  alert(JSON.stringify(setLoginsuccess));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleShowPassword = () => {
    if (showPassword === "password") {
      setIcon(eye);
      setShowPassword("text");
    } else {
      setIcon(eyeOff);
      setShowPassword("password");
    }
    //setShowPassword(showPassword ? false : true)
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (credentials?.username?.trim().length > 0) {
      if (credentials?.password?.trim().length > 0) {
        dispatch(
          USER_LOGIN_ACTION.loginPageStart({
            userName: credentials?.username,
            password: credentials?.password,
            initialLogin: "true",
          })
        );
      } else {
        if (!toast.isActive("passwordError")) {
          toast.error("Password should not be empty!", {
            toastId: "passwordError",
          });
        }
      }
    } else {
      if (!toast.isActive("usernameError")) {
        toast.error("Username should not be empty!", {
          toastId: "usernameError",
        });
      }
    }
  };

  const handleCredentials = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };
  return (
    <>
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing login">
        {/* <div className="form-container outer"> */}
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content login">
                {/* <h1 className="signin">Sign In</h1> */}
                <img src="/assets/img/pixfixLogo.png" />
                {/* <p className="signin mt-2 mb-2">
                  Log in to your streama account to continue.
                </p> */}

                <form
                  className="text-left"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <label for="username">USERNAME</label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <input
                        id="username"
                        name="username"
                        style={{ backgroundColor: "#faf7f6" }}
                        type="text"
                        value={credentials.username}
                        onChange={handleCredentials}
                        className="form-control"
                        placeholder="e.g John_Doe"
                      />
                    </div>

                    <div
                      id="password-field"
                      className="field-wrapper input mb-2"
                    >
                      <div className="d-flex justify-content-between">
                        <label for="password">PASSWORD</label>
                        <a
                          href="/passwordRecovery"
                          className="forgot-pass-link"
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-lock"
                      >
                        <rect
                          x="3"
                          y="11"
                          width="18"
                          height="11"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                      </svg>
                      <input
                        id="password"
                        name="password"
                        type={showPassword}
                        style={{ backgroundColor: "#faf7f6" }}
                        className="form-control"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handleCredentials}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        id="toggle-password"
                        className="feather feather-eye"
                        onClick={handleShowPassword}
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          type="submit"
                          className="btn submit-btn mr-2"
                          value=""
                          onClick={(e) => handleLogin(e)}
                        >
                          Log In
                        </button>
                      </div>
                    </div>

                    <div className="text-center mb-2 mt-3">
                      <span style={{ color: "#3c3c3c", fontWeight: "bold" }}>
                        Streama 1.1.0
                      </span>
                    </div>

                    {/* <div className="footer"> */}
                    <div className="text-center">
                      Copyright.
                      <a target="_blank" href="https://www.riversilica.com/">
                        RiverSilica Technologies Pvt Ltd.
                      </a>
                    </div>

                    {/* </div> */}
                    <div className="text-center mt-1">
                      <a target="_blank" href="https://www.riversilica.com/">
                        <img
                          src="/assets/img/RiverSilica_Logo.png"
                          alt="RiverSilica Logo"
                        />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        closeButton={false}
      /> */}
    </>
  );
};

export default LoginPage;
