import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../../Service/getAllUsers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Users = () => {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState();
  const [list, setList] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [iconClicked, setIconClicked] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const handleSetDataPerPage = (data) => {
    setItemsPerPage(data);
  };

  useEffect(() => {
    getAllUsers()
      .then((response) => {
        // console.log(JSON.stringify(response?.data));
        setList(response?.data?.data);
        setSearchData(response?.data?.data);
      })
      .catch((error) => {
        // console.log("BRUH", error);
        if (!toast.isActive("serverError")) {
          toast.error("Server is not responding!", { toastId: "serverError" });
        }
      });
  }, []);

  const endOffset = itemOffset + itemsPerPage;

  const currentItems =
    list != null &&
    list != undefined &&
    list?.length != null &&
    list?.length != undefined &&
    list?.length > 0 &&
    list?.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(list?.length / itemsPerPage);

  useEffect(() => {
    pageCount = Math.ceil(list?.length / itemsPerPage);
  }, [list]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const searchFunction = (data) => {
    var lowSearch = data.toLowerCase();
    let filteredData = searchData?.filter((wine) =>
      Object.values(wine).some((val) =>
        String(val).toLowerCase().includes(lowSearch)
      )
    );
    setList(filteredData);
    setItemOffset(0);
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((item) => (
            <div key={item.id}>
              {list?.data?.map((a) => (
                <h3>{item.id}</h3>
              ))}
            </div>
          ))}
      </>
    );
  }

  // console.log(JSON.stringify(currentItems));

  const handleSort = (columnName) => {
    let direction = "asc";
    if (sortedColumn === columnName && sortDirection === "asc") {
      direction = "desc";
    }
    setSortDirection(direction);
    setSortedColumn(columnName);

    // const sortedData = [...caseList].sort((a, b) => {
    //   const valueA = a && a[columnName];
    //   const valueB = b && b[columnName];

    //   // Check if valueA and valueB are defined
    //   if (valueA === undefined || valueB === undefined) {
    //     return 0; // or handle this case as you see fit
    //   }

    //   const isNumber = !isNaN(valueA) && !isNaN(valueB);

    //   if (direction === "asc") {
    //     if (isNumber) {
    //       return parseFloat(valueA) - parseFloat(valueB);
    //     } else {
    //       return valueA.localeCompare(valueB);
    //     }
    //   } else {
    //     if (isNumber) {
    //       return parseFloat(valueB) - parseFloat(valueA);
    //     } else {
    //       return valueB.localeCompare(valueA);
    //     }
    //   }
    // });

    // setCaselist(sortedData);
  };

  const handleUpdateUser = () => {
    navigate("/editusers");
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing" id="cancel-row">
          <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div
              class="widget-content widget-content-area br-6"
              // style={{ backgroundColor: "#FBF5FF" }}
            >
              {/* -------------- Header Area ----------------- */}
              <div className="row">
                <div class="col-xl-8 col-md-8 col-sm-8 col-8 mb-3">
                  <h3 class="content-header">Users</h3>
                </div>
              </div>

              {/* -------------------------- Breadcrumb Area ----------------------------------- */}
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dash">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                          fill="#3c3c3c"
                        />
                      </svg>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Users</a>
                  </li>
                </ol>
              </nav>

              {/* -------------------- Main Content Area ---------------------- */}
              <div
                class="table-responsive mb-4 mt-4"
                style={{ overflowX: "scroll" }}
              >
                <div
                  id="zero-config_wrapper"
                  class="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div class="tab-content mb-4" id="border-tabsContent">
                    <div
                      class="tab-pane fade show active"
                      id="border-home"
                      role="tabpanel"
                      aria-labelledby="border-home-tab"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-6 d-flex">
                          <div
                            class="dataTables_length"
                            id="zero-config_length"
                          >
                            <label className="select-search-labels">
                              Results :{" "}
                              <select
                                name="zero-config_length"
                                aria-controls="zero-config"
                                class="form-control select-table-size"
                                onChange={(e) =>
                                  handleSetDataPerPage(e.target.value)
                                }
                              >
                                <option value="7">7</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex d-md-block">
                          <div
                            id="zero-config_filter"
                            class="dataTables_filter"
                          >
                            <label className="select-search-labels">
                              Search:{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-search"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                              <input
                                type="search"
                                class="form-control select-table-size"
                                placeholder="Search..."
                                aria-controls="zero-config"
                                onChange={(e) => searchFunction(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12">
                          <table
                            id="zero-config"
                            class="table table-hover dataTable"
                            style={{
                              // width: "100%",
                              // role: "grid",
                              // ariaDescribedby: "zero-config_info",
                              border: " 1px solid #cccccc",
                              borderCollapse: " collapse",
                            }}
                          >
                            <thead className="table-theader">
                              <tr role="row">
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  UserID
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.length > 0 &&
                                currentItems?.map((item) => {
                                  return (
                                    <tr role="row" key={item?.id}>
                                      <td className="table-tbody-tr">
                                        {item?.userName}
                                      </td>

                                      <td className="table-tbody-tr">
                                        <span title="Update User">
                                          <svg
                                            _ngcontent-orq-c74=""
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-edit"
                                            style={{
                                              marginRight: "10px",
                                              cursor: "pointer",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={() =>
                                              navigate("/editusers", {
                                                state: {
                                                  item: item,
                                                },
                                              })
                                            }
                                          >
                                            <path
                                              _ngcontent-orq-c74=""
                                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                            ></path>
                                            <path
                                              _ngcontent-orq-c74=""
                                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            {list?.length == 0 && (
                              <paragraph>No matching records found</paragraph>
                            )}
                          </div>
                          <Items currentItems={currentItems} />

                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={15}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-7">
                          <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="zero-config_paginate"
                          ></div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
