import React, { useEffect, useState } from "react";
import videojs from "video.js";
import "videojs-contrib-eme"; // Import the DRM plugin
import "@videojs/http-streaming";

const VideoPlayerDrm = ({ source, type }) => {
  const [player, setPlayer] = useState(null);
  let drmKey =
    "https://fpe.ezdrm.com/proxyEngine.aspx?auth=eyJwWCI6IkIzMkU2OCIsImNsaWVudF9pcCI6IjEwLjAuOTAuNDEiLCJjYW5fcGxheSI6IlRydWUifQ==";

  useEffect(() => {
    const initializePlayer = () => {
      const videoElement = document.getElementById("my-video");
      if (videoElement) {
        const newPlayer = videojs(videoElement, {
          controls: true,
          autoplay: false,
          preload: "auto",
          fluid: true,
          plugins: {
            eme: {}, // initialize EME plugin
          },
        });

        setPlayer(newPlayer);
      }
    };

    // Use setTimeout or requestAnimationFrame to ensure DOM is ready
    const timer = setTimeout(initializePlayer, 100);

    return () => {
      clearTimeout(timer);
      if (player) {
        player.dispose();
      }
    };
  }, []);

  useEffect(() => {
    // Update the video source when props change
    if (player) {
      player.on("error yoooo", () => {
        console.log(player.error());
      });
      // Ensure DRM is applied if drmKey is present
      console.log("DRMmm");
      player.src({
        src: source,
        type: type,
        keySystems: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",

            getLicense: (emeOptions, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",

                  url: drmKey,

                  responseType: "arraybuffer",

                  body: keyMessage,
                  Pragma: "no-cache",
                  CacheControl: "no-cache",
                  ContentType: "text/xml; charset=utf-8",
                  Pragma: "no-cache",
                  Expires: "0",
                },

                (err, response, responseBody) => {
                  if (err) {
                    callback(err);

                    return;
                  }

                  callback(null, responseBody);
                }
              );
            },
          },
        },

        plugins: {
          eme: {}, // Pass EME plugin options here if needed
        },
      });
    }
  }, [player, source, type, drmKey]);

  //   player.on("error", () => {
  //     console.log(player.error());
  //   });

  return (
    <div>
      <video
        id="my-video"
        className="video-js vjs-big-play-centered"
        controls
        preload="auto"
        data-setup="{}"
      ></video>
    </div>
  );
};

export default VideoPlayerDrm;

// -------------------------------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import videojs from "video.js";
// import "videojs-contrib-eme"; // Import the DRM plugin
// import "@videojs/http-streaming";

// const VideoPlayerDrm = ({ source, type, drmKey }) => {
//   const [player, setPlayer] = useState(null);

//   useEffect(() => {
//     const initializePlayer = () => {
//       const videoElement = document.getElementById("my-video");
//       if (videoElement) {
//         const newPlayer = videojs(videoElement, {
//           controls: true,
//           autoplay: false,
//           preload: "auto",
//           fluid: true,
//         });

//         setPlayer(newPlayer);
//       }
//     };

//     // Use setTimeout or requestAnimationFrame to ensure DOM is ready
//     const timer = setTimeout(initializePlayer, 100);

//     return () => {
//       clearTimeout(timer);
//       if (player) {
//         player.dispose();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     // Update the video source when props change
//     if (player) {
//       player.on("error yoooo", () => {
//         console.log(player.error());
//       });
//       // Ensure DRM is applied if drmKey is present
//       if (drmKey && drmKey?.length > 0) {
//         console.log("DRMmm");
//         player.src({
//           src: source,
//           type: type,
//           keySystems: {
//             "com.widevine.alpha": {
//               url: drmKey,
//             },
//           },
//         });
//       } else {
//         player.src({ src: source, type: type });
//       }
//     }
//   }, [player, source, type, drmKey]);

//   //   player.on("error", () => {
//   //     console.log(player.error());
//   //   });

//   return (
//     <div>
//       <video
//         id="my-video"
//         className="video-js vjs-big-play-centered"
//         controls
//         preload="auto"
//         data-setup="{}"
//       ></video>
//     </div>
//   );
// };

// export default VideoPlayerDrm;
