import Axios from "axios";
import axiosRetry from "axios-retry";
import CryptoJS from "crypto-js";
import { serverPath } from "../Constants/Constants";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as LOGIN_ACTION from "../Redux/Login/Action";
import store from "../Redux/Store";

const qs = require("qs");

const getAccessToken = () => localStorage.getItem("accessToken");
console.log("AXIOSINSTANCE", getAccessToken());

const SKK =
  "2553eh~$535%@))2837^3jdjej#^*&039*&@14244141)919~`fo0w02j120+0349jfei0a%*!@#@949kd";

const decrypt_this =
  "U2FsdGVkX19l6wMFRioaeKwyKr1WzptR57yooxQBAnY5YS8A/rM/fHmvwts4IYHe6RMmRuEPnyzLXiGAJMrVzADb3rjjGDuCJ39XD/1X0BFcWoMtAF4w4bSRlUJ3X5mqAyCh9K0bMFMooemDF8dIMoij5BvY4G7FDcsWjvPGSCr5ByC90MgdPDb1INyxmGLX4yE/HQLarXstVhIQ60oHNTF+BUoR171Pj5DeCWLy5872Lc/415dLNHygNyL/Y0J22jsQlf7f//DZVlM5b0ZT+KKCq1dslda1GjOYVkS1M9v0YMD4m2t7Aj/ASlLZaqLQIbLfck+2KZbBj/vxnhd1xx2NvVDm5PvWtQBFfMu5NqpWJF7UH4fcxPZ2PD01kKfIvfaITO5GuBf+T0+oOlFCW4mQmJkv1e3TMThD4ohgzk7KveBLIf48Z9upwq+4xGZjZOuCpUoQ06EuiG+8lw7fWbhTvEXb0EoF4IOCLaR54dN3V92UGwOqWWsqkwyboZL8g4ZjJES/DHm8FvLHyoPuMBODBvuZjYPttM7HEf8a/Q0=";

export const encryptData = (data) => {
  console.log("CREATE STREAM ENCRYPT", data);
  let jsonString = JSON.stringify(data);

  return CryptoJS.AES.encrypt(jsonString, SKK).toString();
};

export const decryptData = (cipherText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, SKK);
    // const xxx = CryptoJS.AES.decrypt(decrypt_this, SKK);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    // const decryptedxxx = bytes.toString(CryptoJS.enc.Utf8);
    // console.log("DECRYPTTTTT THID", decryptedxxx);
    return JSON.parse(decryptedString);
    // return JSON.parse(decryptedxxx);
  } catch (error) {
    console.log("Decryption failed:", error);
    return cipherText;
  }
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const userDetails = state.userLoginReducer?.loginCredentials;
    // console.log(
    //   "USER DETAILS AXIOS INSTANCE",
    //   JSON.stringify(userDetails?.data?.data)
    // );

    if (userDetails?.userName && userDetails?.password) {
      store.dispatch(
        LOGIN_ACTION.loginPageStart({
          userName: userDetails.userName,
          password: userDetails.password,
        })
      );

      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const loginStatus = state.userLoginReducer.loginUserData.data.status;

        const refresh =
          state.userLoginReducer.loginUserData.data?.data?.[1]?.access_token;
        if (loginStatus == true && refresh) {
          localStorage.setItem("accessToken", refresh);
          unsubscribe(); // Unsubscribe from store updates
          resolve(refresh); // Resolve with the new token
        } else if (loginStatus == false) {
          unsubscribe(); // Unsubscribe to prevent memory leaks
          store.dispatch(LOGIN_ACTION.logout()); // Dispatch logout action
          reject(new Error("Login failed, user logged out"));
        }
      });
    } else {
      store.dispatch(LOGIN_ACTION.logout()); // Dispatch logout if no user details
      reject(new Error("No user details available, user logged out"));
    }
  });
};

const axiosInstance = () => {
  const headers = {};

  const axiosInstance = Axios.create({
    baseURL: serverPath,
    headers,
  });

  axiosInstance.interceptors.request.use(async (req) => {
    console.log("REQ BODY", req);
    console.log("REQ DATA", req.data?.data);
    const accessToken = getAccessToken();
    if (accessToken) req.headers.Authorization = `Bearer ${accessToken}`;
    if (
      req.data &&
      !req.headers["Content-Type"]?.includes("multipart/form-data") &&
      !req.alreadyEncrypted
    ) {
      if (typeof req.data === "object") {
        req.data = { data: encryptData(req.data) };
        req.alreadyEncrypted = true;
      } else if (typeof req.data === "string") {
        req.data = { data: req.data };
      } else if (typeof req.data.data === "string") {
        console.log("DATA.DATA");
        req.data = { data: req.data };
      }
    }

    return req;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (typeof response.data === "string") {
        response.data = decryptData(response.data);
        console.log("AXIOS RESPONSE DATA", decryptData(response.data));
      }
      return response;
    },
    async (error) => {
      const { response, config } = error;
      const originalRequest = error.config;

      if (response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await refreshToken();
          processQueue(null, newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          isRefreshing = false;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      } else {
        const decryptedError = decryptData(response?.data);

        const errorMessage = decryptedError ? decryptedError.message : "";
        console.log("ERROR MESSAGE", decryptedError);

        if (errorMessage) {
          const toastId = "error-toast"; // Unique ID for the toast

          if (!toast.isActive(toastId)) {
            // Check if the toast is already active
            toast.error(errorMessage, { toastId });
          }
        }

        return Promise.reject(error);
      }
    }
  );

  return axiosInstance;
};

export default axiosInstance;
