import axios from "axios";
import qs from "qs";
import { serverPath } from "../Constants/Constants";
import axiosInstance from "../utils/axiosInstance";

export const userDataApi = async (payload) => {
  return await axiosInstance()
    .get("user", payload, {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getAllUsers = async () => {
  return await axiosInstance()
    .get("user", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      console.log("RES", JSON.stringify(response));
      return response;
    })
    .catch((error) => {
      console.log("Error", JSON.stringify(error));
      return Promise.reject(error);
    });
};

export const updateUser = async (payload) => {
  console.log(JSON.stringify(payload));
  // const data = qs.stringify(payload);
  return await axiosInstance()
    .put("update/user", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};
