import { combineReducers } from "redux";
import userLoginReducer from "./Login/Reducer";
import userDataReducer from "./UserInfo/Reducer";

const rootReducers = combineReducers({
  userLoginReducer,
  userDataReducer,
});

export default rootReducers;
