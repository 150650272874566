import axios from "axios";
import { serverPath } from "../Constants/Constants";
import axiosInstance from "../utils/axiosInstance";

export const getAllSmtp = async (payload) => {
  // console.log("PAYLOAD", JSON.stringify(payload));
  return await axiosInstance()
    .get("smtpConfig", {
      params: {
        userName: payload,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createSmtpApi = async (payload) => {
  return await axiosInstance()
    .post("create/smtpConfig", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateSmtpApi = async (payload) => {
  return axiosInstance()
    .put("update/smtpConfig", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteSmtpApi = async (payload) => {
  return axiosInstance()
    .delete(`delete/smtpConfig/${payload?.id}`, payload?.userId, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
