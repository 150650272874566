import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import "./Sidebar.css";

export const Sidebar = () => {
  const navigatee = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("dashboard");

  function isActive(path) {
    return location.pathname.startsWith(path);
  }

  return (
    <div>
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <div className="sidebar-wrapper sidebar-theme">
          <nav id="sidebar">
            <ul
              className="navbar-nav theme-brand flex-row text-center"
              style={{ backgroundColor: "#3c3c3c" }}
            >
              <li className="nav-item theme-logo">
                <a href="index.html"></a>
              </li>

              <li className="nav-item theme-text">
                <a href="" className="nav-link">
                  <img
                    src="/assets/img/pixfixLogo_sidebar.png"
                    // style={{ paddingLeft: "1px" }}
                    // class="navbar-logo"
                    // alt="logo"
                  />
                </a>
              </li>

              <li className="nav-item toggle-sidebar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-left sidebarCollapse"
                >
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
              </li>
            </ul>

            <ul className="list-unstyled menu-categories" id="accordionExample">
              <li className={`menu ${isActive("/dash") ? "active" : ""}`}>
                <a
                  onClick={() => navigatee("/dash")}
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span className="headingcolor">Dashboard</span>
                  </div>
                </a>
              </li>

              <li className={`menu ${isActive("/streams") ? "active" : ""}`}>
                <a
                  onClick={() => navigatee("/streams")}
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-activity"
                    >
                      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                    </svg>
                    <span className="headingcolor">Live Streams</span>
                  </div>
                </a>
              </li>
              <li className={`menu ${isActive("/vod") ? "active" : ""}`}>
                <a
                  onClick={() => navigatee("/vod")}
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-video"
                    >
                      <polygon points="23 7 16 12 23 17 23 7"></polygon>
                      <rect
                        x="1"
                        y="5"
                        width="15"
                        height="14"
                        rx="2"
                        ry="2"
                      ></rect>
                    </svg>
                    <span className="headingcolor">VOD</span>
                  </div>
                </a>
              </li>
              <li className={`menu ${isActive("/users") ? "active" : ""}`}>
                <a
                  onClick={() => navigatee("/users")}
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>{" "}
                    <span className="headingcolor">Users</span>
                    <span className="headingcolor"></span>
                  </div>
                </a>
              </li>

              <li className="menu">
                <a
                  href="#components"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    <span className="headingcolor">Settings</span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </a>
                <ol
                  className="collapse submenu list-unstyled"
                  id="components"
                  data-parent="#accordionExample"
                >
                  {/* <li
                    className={`menu ${
                      isActive("/applanguagelisting") ||
                      isActive("/createapplanguage") ||
                      isActive("/updateapplanguage")
                        ? "active"
                        : ""
                    }`}
                  >
                    <a
                      onClick={() => navigatee("/applanguagelisting")}
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
                            fill="#E4D9D3"
                          />
                        </svg>

                        <span className="submenucolor">App Language</span>
                      </div>
                    </a>
                  </li> */}
                  <li
                    className={`menu ${
                      isActive("/configeditor") ? "active" : ""
                    }`}
                  >
                    <a
                      onClick={() => navigatee("/configeditor")}
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
                            fill="#E4D9D3"
                          />
                        </svg>

                        <span className="submenucolor">Config Editor</span>
                      </div>
                    </a>
                  </li>
                  <li className={`menu ${isActive("/smtp") ? "active" : ""}`}>
                    <a
                      onClick={() => navigatee("/smtp")}
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
                            fill="#E4D9D3"
                          />
                        </svg>

                        <span className="submenucolor">SMTP</span>
                      </div>
                    </a>
                  </li>
                  <li
                    className={`menu ${isActive("/license") ? "active" : ""}`}
                  >
                    <a
                      onClick={() => navigatee("/license")}
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
                            fill="#E4D9D3"
                          />
                        </svg>

                        <span className="submenucolor">License</span>
                      </div>
                    </a>
                  </li>
                  <li
                    className={`menu ${
                      isActive("/notification") ? "active" : ""
                    }`}
                  >
                    <a
                      onClick={() => navigatee("/notification")}
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
                            fill="#E4D9D3"
                          />
                        </svg>

                        <span className="submenucolor">Notification</span>
                      </div>
                    </a>
                  </li>
                </ol>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

// // Sidebar.js

// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
// import "./Sidebar.css";

// export const Sidebar = () => {
//   const navigatee = useNavigate();
//   const location = useLocation();
//   const [active, setActive] = useState("dashboard");
//   // const[activecase,setActivecase]=useState()

//   function isActive(path) {
//     return window.location.pathname.startsWith(path);
//   }

//   console.log("Current URL:", window.location.pathname);

//   const handleDashBoard = () => {
//     setActive("dashboard");
//   };
//   const handleCase = () => {
//     setActive("Case");
//   };
//   const handleCourtRoom = () => {
//     setActive("CourtRoomListing");
//   };
//   return (
//     <div>
//       <div className="main-container" id="container">
//         <div className="overlay"></div>
//         <div className="search-overlay"></div>

//         <div className="sidebar-wrapper sidebar-theme">
//           <nav id="sidebar">
//             <ul
//               className="navbar-nav theme-brand flex-row text-center"
//               style={{ backgroundColor: "#253152" }}
//             >
//               <li className="nav-item theme-logo">
//                 <a href="index.html">
//                   {/* <img
//                     // src="assets/img/90x90.jpg"
//                     className="navbar-logo"
//                     alt="logo"
//                   /> */}
//                 </a>
//               </li>

//               <li className="nav-item theme-text">
//                 <a href="index.html" className="nav-link">
//                   {/* <img
//                     src="https://media.9curry.com/uploads/organization/image/2816/Ranchi_District_Court_Logo.png"
//                     // alt=""
//                     style={{ width: "50px", height: "50px" }}
//                   /> */}
//                   CMS
//                 </a>
//               </li>

//               <li className="nav-item toggle-sidebar">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                   stroke="currentColor"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   className="feather feather-arrow-left sidebarCollapse"
//                 >
//                   <line x1="19" y1="12" x2="5" y2="12"></line>

//                   <polyline points="12 19 5 12 12 5"></polyline>
//                 </svg>
//               </li>
//             </ul>

//             <ul className="list-unstyled menu-categories" id="accordionExample">
//               <li className={`menu ${isActive("/dash") ? "active" : ""}`}>
//                 <a
//                   onClick={() => navigatee("/dash")}
//                   aria-expanded="false"
//                   className="dropdown-toggle"
//                 >
//                   <div className="">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       stroke-width="2"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       className="feather feather-home"
//                     >
//                       <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
//                       <polyline points="9 22 9 12 15 12 15 22"></polyline>
//                     </svg>
//                     <span className="headingcolor">Dashboard</span>
//                   </div>
//                 </a>
//               </li>

//     <li className={`menu ${isActive("/role") ? "active" : ""}`}>
//                 <a
//                   onClick={() => navigatee("/role")}
//                   aria-expanded="false"
//                   className="dropdown-toggle"
//                 >
//                   <div className="">
//                     <svg
//                       _ngcontent-xfw-c149=""
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       stroke-width="2"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       className="feather feather-user-check"
//                     >
//                       <path
//                         _ngcontent-xfw-c149=""
//                         d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
//                       ></path>
//                       <circle
//                         _ngcontent-xfw-c149=""
//                         cx="8.5"
//                         cy="7"
//                         r="4"
//                       ></circle>
//                       <polyline
//                         _ngcontent-xfw-c149=""
//                         points="17 11 19 13 23 9"
//                       ></polyline>
//                     </svg>
//                     <span className="headingcolor">Role</span>
//                   </div>
//                 </a>
//               </li>

//               <li className="menu">
//                 <a
//                   href="#auth"
//                   data-toggle="collapse"
//                   aria-expanded="false"
//                   class="dropdown-toggle"
//                 >
//                   <div className="">
//                     <svg
//                       _ngcontent-xfw-c149=""
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       stroke-width="2"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       className="feather feather-authorizationlisting"
//                     >
//                       <path
//                         _ngcontent-xfw-c149=""
//                         d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
//                       ></path>
//                       <circle
//                         _ngcontent-xfw-c149=""
//                         cx="9"
//                         cy="7"
//                         r="4"
//                       ></circle>
//                       <path
//                         _ngcontent-xfw-c149=""
//                         d="M23 21v-2a4 4 0 0 0-3-3.87"
//                       ></path>
//                       <path
//                         _ngcontent-xfw-c149=""
//                         d="M16 3.13a4 4 0 0 1 0 7.75"
//                       ></path>
//                     </svg>
//                     <span className="headingcolor">Authorization</span>
//                   </div>
//                   <div>
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       strokeWidth="2"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       className="feather feather-chevron-right"
//                     >
//                       <polyline points="9 18 15 12 9 6"></polyline>
//                     </svg>
//                   </div>
//                 </a>
//                 <ul
//                   class="collapse submenu list-unstyled"
//                   id="auth"
//                   data-parent="#accordionExample"
//                 >
//                   <li
//                     className={`menu ${
//                       isActive("/authorizationlisting")  ? "active"
//                       : ""
//                       // isActive("/CreateUser") ||
//                       // isActive("/UpdateUser")

//                     }`}
//                   >
//                     <a
//                       aria-expanded="false"
//                       className="dropdown-toggle"
//                       onClick={() => navigatee("/authorizationlisting")}
//                     >
//                       <div className="">
//                         <span className="submenucolor">Listing</span>
//                       </div>
//                     </a>
//                   </li>

//                   <li
//                     className={`menu ${
//                       isActive("/nationlisting")
//                         ? "active"
//                         : ""
//                     }`}
//                   >
//                     <a
//                       aria-expanded="false"
//                       className="dropdown-toggle"
//                       onClick={() => navigatee("/nationlisting")}
//                     >
//                       <div className="">
//                         <span className="submenucolor">Nation</span>
//                       </div>
//                     </a>
//                   </li>
//                 </ul>
//               </li>

//               <li className="menu">
//                 <a
//                   href="#components"
//                   data-toggle="collapse"
//                   aria-expanded="false"
//                   className="dropdown-toggle"
//                 >
//                   <div className="">
//                     <svg
//                       _ngcontent-xfw-c149=""
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       stroke-width="2"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       className="feather feather-settings"
//                     >
//                       <circle
//                         _ngcontent-xfw-c149=""
//                         cx="12"
//                         cy="12"
//                         r="3"
//                       ></circle>
//                       <path
//                         _ngcontent-xfw-c149=""
//                         d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
//                       ></path>
//                     </svg>
//                     <span className="headingcolor">Settings</span>
//                   </div>
//                   <div>
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       stroke="currentColor"
//                       strokeWidth="2"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       className="feather feather-chevron-right"
//                     >
//                       <polyline points="9 18 15 12 9 6"></polyline>
//                     </svg>
//                   </div>
//                 </a>
//                 <ul
//                   className="collapse submenu list-unstyled"
//                   id="components"
//                   data-parent="#accordionExample"
//                 >
//                   {/* <li
//                     className={`menu ${
//                       isActive("/smtp") ||
//                       isActive("/createsmtp") ||
//                       isActive("/updatesmtp")
//                         ? "active"
//                         : ""
//                     }`}
//                   >
//                     <a
//                       onClick={() => navigatee("/smtp")}
//                       aria-expanded="false"
//                       className="dropdown-toggle"
//                     >
//                       <div className="">
//                         <span className="submenucolor">SMTP</span>
//                       </div>
//                     </a>
//                   </li> */}
//                   <li
//                     className={`menu ${
//                       isActive("/applanguagelisting")
//                       // ||
//                       // isActive("/createsmtp") ||
//                       // isActive("/updatesmtp")
//                         ? "active"
//                         : ""
//                     }`}
//                   >
//                     <a
//                       onClick={() => navigatee("/applanguagelisting")}
//                       aria-expanded="false"
//                       className="dropdown-toggle"
//                     >
//                       <div className="">
//                         <span className="submenucolor">App Language</span>
//                       </div>
//                     </a>
//                   </li>

//                   {/* <li
//                     className={`menu ${
//                       isActive("/trancoderlisting") ||
//                       isActive("/createtranscoder") ||
//                       isActive("/updatetranscoder")
//                         ? "active"
//                         : ""
//                     }`}
//                   >
//                     <a
//                       onClick={() => navigatee("/trancoderlisting")}
//                       aria-expanded="false"
//                       className="dropdown-toggle"
//                     >
//                       <div className="">
//                         <span className="submenucolor">Transcoder</span>
//                       </div>
//                     </a>
//                   </li> */}
//                 </ul>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;
