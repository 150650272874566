import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStructuredSelector } from "reselect";
import { userDataSelector } from "../Redux/Login/Selector";
import { connect, useDispatch } from "react-redux";
import * as LOGOUT_ACTION from "../Redux/Login/Action";

// const navigate=useNavigate()
const Navbar = ({ LOGIN_USER_DATA }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log("LLLL", JSON.stringify(LOGIN_USER_DATA?.data?.data));
  // const navigatee = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    // Detect screen size
    const handleResize = () => {
      setSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on load

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const logoutTimeoutRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSmallScreen, setSmallScreen] = useState(false);

  const handleLogOut = () => {
    clearTimeout(logoutTimeoutRef.current);

    localStorage.removeItem("userData");
    localStorage.removeItem("isAuthenticated");
    dispatch(LOGOUT_ACTION.logout());
    navigate("/");
  };
  const handleOutsideClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  const handleActivity = () => {
    localStorage.setItem("lastActivityTime", new Date().getTime().toString());
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const resetLogoutTimeout = () => {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = setTimeout(handleLogOut, 15 * 60 * 1000);
    };

    const handleUserInteraction = () => {
      resetLogoutTimeout();
    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        handleUserInteraction();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("mousemove", handleUserInteraction);

    resetLogoutTimeout();

    return () => {
      clearTimeout(logoutTimeoutRef.current);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("mousemove", handleUserInteraction);
    };
  }, []);

  // useEffect(() => {
  //   const inactivityTimer = setInterval(() => {
  //     const lastActivityTime = localStorage.getItem("lastActivityTime");
  //     if (lastActivityTime) {
  //       const currentTime = new Date().getTime();
  //       const timeDifference = currentTime - parseInt(lastActivityTime, 2);

  //       if (timeDifference > 600000) {
  //         handleLogOut();
  //       }
  //     }
  //   }, 60000);

  //   return () => clearInterval(inactivityTimer);
  // }, []);
  return (
    <div>
      {/* <div id="load_screen"> <div class="loader"> <div class="loader-content">
        <div class="spinner-grow align-self-center"></div>
    </div></div></div> */}
      <div className="header-container fixed-top">
        <header
          className="header navbar navbar-expand-sm"
          onClick={handleActivity}
        >
          <a
            href="javascript:void(0);"
            className="sidebarCollapse"
            data-placement="bottom"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </a>

          <ul className="navbar-item flex-row">
            <li className="nav-item align-self-center page-heading">
              <div className="page-header">
                <div className="page-title">
                  {/* <h3 onClick={()=>navigate('/new')}> Analytics</h3> */}
                </div>
              </div>
            </li>
          </ul>

          <ul className="navbar-item flex-row search-ul">
            {/* <li className="nav-item align-self-center search-animated">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search toggle-search">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <form className="form-inline search-full form-inline search" role="search">
                <div className="search-bar">
                  <input type="text" className="form-control search-form-control ml-lg-auto" placeholder="Search..." />
                </div>
              </form>
            </li> */}
          </ul>

          <ul className="navbar-item flex-row network-tab">
            <li className="nav-item network-tab-item">
              <span style={{ color: "#8a8a8a" }}>1.0.0</span>
            </li>
            <li className="nav-item network-tab-item">
              <span style={{ color: "#8a8a8a" }}>STREAMS:</span> 2/30
            </li>
            {isSmallScreen && (
              <div class="btn-group network-tab">
                <svg
                  class="btn btn-sm dropdown-toggle network-tab"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#E4D9D3" />
                  <circle cx="11.5996" cy="12.3994" r="2" fill="#3C3C3C" />
                  <circle cx="4.40039" cy="12.3994" r="2" fill="#3C3C3C" />
                  <circle cx="18.7998" cy="12.3994" r="2" fill="#3C3C3C" />
                </svg>

                {/* <button
                  class="btn btn-sm dropdown-toggle network-tab"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  ...
                </button> */}
                <ul
                  class="dropdown-menu"
                  style={{ backgroundColor: "#3c3c3c" }}
                >
                  <li className="nav-item network-tab-item">FILES: 20</li>
                  <li className="nav-item network-tab-item">CLIENTS: 0</li>
                  <li className="nav-item network-tab-item">IN: 0</li>
                  <li className="nav-item network-tab-item">OUT: 0</li>
                  <li className="nav-item network-tab-item">UPTIME: 0</li>
                </ul>
              </div>
            )}

            {!isSmallScreen && (
              <>
                <li className="nav-item network-tab-item">
                  <span style={{ color: "#8a8a8a" }}>FILES:</span> 20
                </li>
                <li className="nav-item network-tab-item">
                  <span style={{ color: "#8a8a8a" }}>CLIENTS:</span> 0
                </li>
                <li className="nav-item network-tab-item">
                  <span style={{ color: "#8a8a8a" }}>IN:</span> 0 MBPS
                </li>
                <li className="nav-item network-tab-item">
                  <span style={{ color: "#8a8a8a" }}>OUT:</span> 0 MBPS
                </li>
                <li className="nav-item network-tab-item">
                  <span style={{ color: "#8a8a8a" }}>UPTIME:</span> 21:50:32
                </li>
              </>
            )}
          </ul>

          <ul className="navbar-item flex-row navbar-dropdown">
            <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
              <a
                href="javascript:void(0);"
                className="nav-link dropdown-toggle user"
                id="userProfileDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ fontSize: "14px" }}
              >
                {LOGIN_USER_DATA?.data?.data[0]?.userName}
              </a>

              <div
                className="dropdown-menu position-absolute signout"
                aria-labelledby="userProfileDropdown"
                // style={{background}}
              >
                <div>
                  <div
                    className="dropdown-item"
                    style={{ backgroundColor: "#3c3c3c" }}
                  >
                    <a
                      className=""
                      style={{ color: "#e4d9d3" }}
                      onClick={() => handleLogOut()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>{" "}
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </header>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LOGIN_USER_DATA: userDataSelector,
});

export default connect(mapStateToProps)(Navbar);
