import axiosInstance from "../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const loginUserData = async (payload) => {
  console.log("SERVICE PAYLOAD", JSON.stringify(payload));
  let data = {};
  data = {
    userName: payload?.userName,
    password: payload?.password,
  };
  return await axiosInstance()
    .post("login", data, {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      console.log("RES", response);
      // alert(JSON.stringify(response));
      return response;
    })
    .catch((error) => {
      console.log("ERROR SERVICE", JSON.stringify(error));
      // toast.error(error?.message);
      return Promise.reject(error);
    });
};
