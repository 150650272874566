import ACTION_TYPES from "./Action.Types";

const { USER_INFO_START, USER_INFO_SUCCESS, USER_INFO_FAILURE } = ACTION_TYPES;

const initialState = {
  userData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_INFO_START:
      return {
        ...state,
        userData: {},
      };

    case USER_INFO_SUCCESS:
      return {
        ...state,
        userData: payload,
      };

    case USER_INFO_FAILURE:
      return {
        ...state,
        userData: {},
      };

    default:
      return state;
  }
};
