import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Navbar } from "./component/Navbar";
import { Sidebar } from "./component/Sidebar";
import LoginPage from "./component/Pages/Loginpage/LoginPage";
import Login from "./component/Pages/Loginpage/Login";
import { Dashboard } from "./component/Pages/Dashboard/Dashboard";
import PasswordRecovery from "./component/Pages/Loginpage/PasswordRecovery/PasswordRecovery";
import Main from "./component/Main";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const [loginsuccess, setLoginsuccess] = useState(false);
  return (
    <div className="App">
      {/* {loginsuccess == false && <Navbar setLoginsuccess={setLoginsuccess} />}
      {loginsuccess == false && <Sidebar setLoginsuccess={setLoginsuccess} />} */}

      {/* <div class="overlay"></div> */}
      {/* <div class="search-overlay"></div> */}
      {/* <Sidebar /> */}
      <Routes basename="/">
        <Route
          path="/"
          exact
          element={<LoginPage setLoginsuccess={setLoginsuccess} />}
        ></Route>
      </Routes>
      {loginsuccess == false && (
        <Main loginsuccess={loginsuccess} setLoginsuccess={setLoginsuccess} />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        closeButton={false}
      />
    </div>
  );
}
