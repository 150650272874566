import ACTION_TYPES from "./Action.Types";

const { LOGIN_PAGE_START, LOGIN_PAGE_SUCCESS, LOGIN_PAGE_FAILURE, LOGOUT } =
  ACTION_TYPES;

export const loginPageStart = (payload) => ({
  type: LOGIN_PAGE_START,
  payload,
});

export const loginPageSuccess = (payload) => ({
  type: LOGIN_PAGE_SUCCESS,
  payload,
});

export const loginPageFailure = (payload) => ({
  type: LOGIN_PAGE_FAILURE,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});
