import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import VideoPlayer from "../../VideoPlayer";
import Select from "react-select";
import { updateLiveStreamApi } from "../../../Service/LiveStream";
import { userDataSelector } from "../../../Redux/Login/Selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditStreams = ({ LOGIN_USER_DATA }) => {
  const userID = LOGIN_USER_DATA?.data?.data[0]?._id;
  const userName = LOGIN_USER_DATA?.data?.data[0]?.userName;
  const navigate = useNavigate();
  const location = useLocation();
  const [formComplete, setFormComplete] = useState(false);
  const [activeTab, setActiveTab] = useState("pills-home");
  const [formData, setFormData] = useState({
    streamName: "",
  });
  const [addInputUrls, setAddInputUrls] = useState({
    urls: [],
  });
  const [multiSelect, setMultiSelect] = useState({
    multi_bitrate_name: [],
    multi_audio_name: [],
  });

  const [formattedBitrate, setFormattedBitrate] = useState([]);
  const [formattedAudioName, setFormattedAudioName] = useState([]);

  const [dvrSelected, setDvrSelected] = useState(false);
  const [drmSelected, setDrmSelected] = useState(false);

  const [dvrData, setDvrData] = useState({
    dvr_period: null,
    dvr_slider_duration: null,
  });
  const [key, setKey] = useState("input");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const source = "http://rt-glb.rttv.com/live/rtnews/playlist_800Kb.m3u8";
  const type = "application/x-mpegURL";

  useEffect(() => {
    const liveStreamData = location?.state?.item;
    console.log("LLL", JSON.stringify(liveStreamData));
    setFormData({ streamName: liveStreamData?.stream_name });
    setAddInputUrls({ urls: liveStreamData?.urls });
    if (liveStreamData?.stream_name && liveStreamData?.urls?.length > 0) {
      setFormComplete(true);
    }
    setMultiSelect({
      multi_bitrate_name: liveStreamData?.multi_bitrate_name,
      multi_audio_name: liveStreamData?.multi_audio_name,
    });

    let formattedBitrateArray = liveStreamData?.multi_bitrate_name?.map(
      (item) => ({
        value: item,
        label: item,
      })
    );
    setFormattedBitrate(formattedBitrateArray);

    let formattedAudioArray = liveStreamData?.multi_audio_name?.map((item) => ({
      value: item,
      label: item,
    }));

    setFormattedAudioName(formattedAudioArray);

    if (liveStreamData?.is_dvr_required == 1) {
      setDvrSelected(true);
    } else {
      setDvrSelected(false);
    }

    if (liveStreamData?.isDRM == 1) {
      setDrmSelected(true);
    } else {
      setDrmSelected(false);
    }

    setDvrData({
      dvr_period: liveStreamData?.dvr_period,
      dvr_slider_duration: liveStreamData?.dvr_slider_duration,
    });
  }, []);

  let optionList = [
    { value: "480", label: "480" },
    { value: "720", label: "720" },
    { value: "1080", label: "1080" },
  ];

  let audioOptionList = [
    { value: "kan", label: "kan" },
    { value: "eng", label: "eng" },
    { value: "hin", label: "hin" },
  ];

  const handleInputChange = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(true);

    const value = e.target.value;
    const updatedFormData = { ...formData, [e.target.name]: value };
    setFormData(updatedFormData);

    // Check if form is complete
    if (updatedFormData.streamName && addInputUrls?.urls?.length > 0) {
      // console.log("TRUEEE BRO");
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    let urlArray = [];

    if (value) {
      urlArray = value
        .split(",")
        .map((url) => url.trim())
        .filter((url) => url); // Split, trim, and filter out empty strings
    }

    setAddInputUrls({
      ...addInputUrls,
      urls: urlArray, // This will correctly reflect the new state
    });

    console.log("LEN", urlArray);
    if (formData.streamName && urlArray.length > 0) {
      setFormComplete(true);
      // Show toast notification when form is complete
      // toast.success("Form is complete!");
    } else {
      setFormComplete(false);
    }
  };

  const handleTabSwitch = (selectedKey, e) => {
    if (!formComplete) {
      // console.log(selectedKey);
      toast.dismiss();
      toast.error("Stream Name, Stream URL cannot be empty!");
      return;
    }
    setKey(selectedKey);
  };

  const handleMultiSelect = (e, selectName) => {
    const value = e;

    let updatedSelect = {
      ...multiSelect,
      [selectName]: e.map((item) => item?.value),
    };

    if (selectName === "multi_bitrate_name") {
      const formattedBitrateArray = e.map((item) => ({
        value: item?.value,
        label: item?.label,
      }));
      setFormattedBitrate(formattedBitrateArray);
    } else if (selectName === "multi_audio_name") {
      const formattedAudioArray = e.map((item) => ({
        label: item?.label,
        value: item?.value,
      }));
      setFormattedAudioName(formattedAudioArray);
    }
    console.log("PLLALLA", JSON.stringify(updatedSelect));
    setMultiSelect(updatedSelect);
  };

  const handleDvrChange = (e) => {
    const value = e.target.value;

    let updatedDvr = { ...dvrData, [e.target.name]: value };
    setDvrData(updatedDvr);
  };

  const handleDrm = (e) => {
    setDrmSelected(!drmSelected);
  };

  const handleDvrSelected = (e) => {
    setDvrSelected(e.target.checked);
  };

  const handleOutputSubmit = (e) => {
    e.preventDefault();
    let updatedStreamData = {};

    updatedStreamData = {
      stream_name: formData?.streamName,
      urls: addInputUrls?.urls,
      multi_bitrate_name: multiSelect?.multi_bitrate_name,
      multi_audio_name: multiSelect?.multi_audio_name,
      is_dvr_required: dvrSelected ? 1 : 0,
      dvr_period: dvrData?.dvr_period,
      dvr_slider_duration: dvrData?.dvr_slider_duration,
      isDRM: drmSelected ? 1 : 0,
      updatedBy: userID,
      userName: userName,
      id: localStorage?.state?.item?._id,
    };

    console.log("UPDATE DATA", JSON.stringify(updatedStreamData));

    updateLiveStreamApi(updatedStreamData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  };

  const handleCancel = (e) => {
    if (hasUnsavedChanges) {
      Swal.fire({
        html:
          '<div style="display: flex; align-items: center;">' +
          '<div style="margin-right: 10px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path> <line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> </div>' +
          "<span>Changes made may not be saved.</span>" +
          "</div>",

        showCancelButton: true,
        background: "black",
        color: "white",
        position: "top-right",
        customClass: "swal-width",
        confirmButtonColor: "#3c3c3c",
        cancelButtonColor: "#E7515A",
        confirmButtonText: "Leave",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          if (e != "/dash") {
            navigate("/streams");
          } else {
            navigate("/dash");
          }
        }
      });
    } else {
      if (e != "/dash") {
        navigate("/streams");
      } else {
        navigate("/dash");
      }
    }
  };

  const handlePreview = (e) => {
    setPreviewEnabled(e.target.checked);
  };

  return (
    <>
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row layout-top-spacing" id="cancel-row">
            <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div class="widget-content widget-content-area br-6">
                {/* ---------- Header Region --------------- */}
                <div className="row">
                  <div class="col-xl-8 col-md-8 col-sm-8 col-8 mb-3">
                    <h3 class="content-header">Edit Streams</h3>
                  </div>
                </div>

                {/* --------------- Breadcrumb Region ------------- */}
                <nav className="breadcrumb-one" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => handleCancel("/dash")}
                      >
                        <path
                          d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                          fill="#3c3c3c"
                        />
                      </svg>
                    </li>
                    <li class="breadcrumb-item active">
                      <span
                        onClick={(e) => handleCancel(e)}
                        style={{ cursor: "pointer" }}
                      >
                        Streams
                      </span>
                    </li>

                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">Edit Streams</a>
                    </li>
                    {/* <li class="breadcrumb-item active" aria-current="page">
                      <span>UI Kit</span>
                    </li> */}
                  </ol>
                </nav>

                {/* ---------------- Main Content Region --------------- */}

                <div
                  class="table-responsive mb-4 mt-4"
                  style={{ overflow: "hidden" }}
                >
                  <div
                    id="zero-config_wrapper"
                    class="dataTables_wrapper container-fluid dt-bootstrap4"
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(e) => handleTabSwitch(e)}
                      className="tab-switch mb-3"
                    >
                      <Tab eventKey="input" title="Input">
                        <div className="row">
                          <div
                            id="tableSimple"
                            className="col-lg-12 col-12 layout-spacing"
                          >
                            <div className="statbox widget box box-shadow">
                              <div className="widget-content widget-content-area">
                                {/* <div className="table-responsive"> */}
                                <form>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <label
                                          className="create-update-labels"
                                          htmlFor=""
                                        >
                                          Stream Name
                                          <span
                                            className="requireddot"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </label>
                                        <input
                                          name="streamName"
                                          type="text"
                                          className="form-control input-form"
                                          placeholder="Stream Name"
                                          value={formData.streamName}
                                          onChange={(e) => handleInputChange(e)}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <label
                                          className="create-update-labels"
                                          htmlFor=""
                                        >
                                          Stream URL
                                          <span
                                            className="requireddot"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </label>
                                        <input
                                          name="streamURL"
                                          type="text"
                                          className="form-control input-form"
                                          placeholder="Stream URL"
                                          value={addInputUrls?.urls}
                                          onChange={(e) => handleUrlChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <button
                                    type="submit"
                                    className="btn submit-btn mr-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleTabSwitch("output");
                                    }}
                                  >
                                    Save
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-danger mr-2"
                                    onClick={(e) => handleCancel(e)}
                                  >
                                    Cancel
                                  </button>
                                  {formComplete ? (
                                    <span>
                                      <Switch
                                        {...label}
                                        color="default"
                                        checked={previewEnabled}
                                        onChange={(e) => handlePreview(e)}
                                      />
                                      <label style={{ color: "#3c3c3c" }}>
                                        Enable Preview
                                      </label>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </form>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                          <div
                            id="tableSimple"
                            className="col-lg-12 col-12 layout-spacing"
                          >
                            {previewEnabled ? (
                              <div class="embed-responsive embed-responsive-16by9">
                                <iframe
                                  class="embed-responsive-item"
                                  src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Tab>
                      {/* <Tab eventKey="preview" title="Preview">
                        <div className="row">
                          <div
                            id="tableSimple"
                            className="col-lg-12 col-12 layout-spacing"
                          >
                            <div className="statbox widget box box-shadow">
                              <div className="widget-content widget-content-area">
                                <div class="embed-responsive embed-responsive-16by9">
                                  <iframe
                                    class="embed-responsive-item"
                                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                                <div class="widget-content widget-content-area">
                                  <div class="card component-card_1">
                                    <div class="card-body">
                                      <h5 class="card-title">
                                        Input Media Info
                                      </h5>
                                      <p class="card-text">
                                        Mauris nisi felis, placerat in volutpat
                                        id, varius et sapien.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab> */}
                      <Tab eventKey="output" title="Output">
                        <div className="row">
                          <div
                            id="tableSimple"
                            className="col-lg-12 col-12 layout-spacing"
                          >
                            <div className="statbox widget box box-shadow">
                              <div className="widget-content widget-content-area">
                                {/* <div className="table-responsive"> */}
                                <form>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <label
                                          className="create-update-labels"
                                          htmlFor=""
                                        >
                                          Multi Bitrate
                                          <span
                                            className="requireddot"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </label>

                                        <Select
                                          name="multi_bitrate_name"
                                          type="checkbox"
                                          options={optionList}
                                          placeholder="Select Bitrates"
                                          // className="yyyyy"
                                          value={formattedBitrate}
                                          // style={{ colourColoumn: "red" }}
                                          onChange={(e) =>
                                            handleMultiSelect(
                                              e,
                                              "multi_bitrate_name"
                                            )
                                          }
                                          isSearchable={true}
                                          isMulti
                                          required
                                        ></Select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <label
                                          className="create-update-labels"
                                          htmlFor=""
                                        >
                                          Multi Audio Tracks
                                          <span
                                            className="requireddot"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          type="checkbox"
                                          options={audioOptionList}
                                          placeholder="Select Audio Tracks"
                                          // className="yyyyy"
                                          value={formattedAudioName}
                                          // style={{ colourColoumn: "red" }}
                                          onChange={(e) =>
                                            handleMultiSelect(
                                              e,
                                              "multi_audio_name"
                                            )
                                          }
                                          isSearchable={true}
                                          isMulti
                                          required
                                        ></Select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <label className="create-update-labels">
                                          DVR
                                        </label>
                                        <Switch
                                          {...label}
                                          color="default"
                                          checked={dvrSelected}
                                          onChange={(e) => handleDvrSelected(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group mb-4">
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            checked={drmSelected}
                                            onChange={(e) => handleDrm(e)}
                                            id="flexCheckChecked"
                                          />
                                          <label
                                            className="create-update-labels"
                                            for="flexCheckChecked"
                                          >
                                            DRM
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {dvrSelected ? (
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-4">
                                          <label
                                            className="create-update-labels"
                                            htmlFor=""
                                          >
                                            DVR period
                                            <span
                                              className="requireddot"
                                              style={{ color: "red" }}
                                            >
                                              *
                                            </span>
                                          </label>
                                          <input
                                            name="dvr_period"
                                            type="text"
                                            className="form-control input-form"
                                            placeholder="Stream URL"
                                            value={dvrData?.dvr_period}
                                            onChange={(e) => handleDvrChange(e)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-4">
                                          <label
                                            className="create-update-labels"
                                            htmlFor=""
                                          >
                                            DVR slider duration
                                            <span
                                              className="requireddot"
                                              style={{ color: "red" }}
                                            >
                                              *
                                            </span>
                                          </label>
                                          <input
                                            name="dvr_slider_duration"
                                            type="text"
                                            className="form-control input-form"
                                            placeholder="Stream URL"
                                            value={dvrData?.dvr_slider_duration}
                                            onChange={(e) => handleDvrChange(e)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </form>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn submit-btn mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            // handleTabSwitch("preview");
                            handleOutputSubmit(e);
                          }}
                        >
                          Submit
                        </button>

                        <button
                          type="button"
                          className="btn btn-danger mr-2"
                          onClick={(e) => handleCancel(e)}
                        >
                          Cancel
                        </button>
                      </Tab>
                      {/* <Tab
                        className="tab-switch"
                        eventKey="overview"
                        title="Overview"
                      >
                        <div className="row">
                          <div
                            id="tableSimple"
                            className="col-lg-12 col-12 layout-spacing"
                          >
                            <div className="statbox widget box box-shadow">
                              <div className="widget-content widget-content-area">
                                <VideoPlayer source={source} type={type} />
                                <div class="widget-content widget-content-area">
                                  <div class="card component-card_1">
                                    <div class="card-body">
                                      <h5 class="card-title">
                                        Input Media Info
                                      </h5>
                                      <p class="card-text">
                                        Mauris nisi felis, placerat in volutpat
                                        id, varius et sapien.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab> */}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        closeButton={false}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  LOGIN_USER_DATA: userDataSelector,
});

export default connect(mapStateToProps)(EditStreams);
