import axiosInstance from "../utils/axiosInstance";

export const getAllLiveStreamApi = async (payload) => {
  return await axiosInstance()
    .get("liveStreamConfig", payload, {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const createLiveStreamApi = async (payload) => {
  console.log("SERVICE", JSON.stringify(payload));
  return await axiosInstance()
    .post("create/liveStreamConfig", payload, {
      // headers: {
      //   "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      // },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const updateLiveStreamApi = async (payload) => {
  return await axiosInstance()
    .put("update/liveStreamConfig", payload, {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const deleteLiveStreamApi = async (payload) => {
  return await axiosInstance()
    .delete(`delete/liveStreamConfig/${payload?.id}`, payload?.userName, {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};
