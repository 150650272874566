import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUser } from "../../../Service/getAllUsers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const EditUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editPersonalData, setEditPersonalData] = useState({
    _id: "",
    userName: "",
    firstName: "",
    lastName: "",
    dob: null,
    mobileNumber: "",
    emailId: "",
    gender: "",
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // console.log("LALALA", JSON.stringify(userData));
  useEffect(() => {
    const userData = location?.state?.item;
    // console.log("BRUH", JSON.stringify(userData?.dob));
    // date = new Date(userData?.dob).toLocaleDateString());
    // console.log("BRUHHH", userData);
    if (userData) {
      const dob = new Date(userData?.dob);

      // Format the date to "dd-mm-yyyy"
      const formattedDOB = `${dob.getFullYear()}-${(
        "0" +
        (dob.getMonth() + 1)
      ).slice(-2)}-${("0" + dob.getDate()).slice(-2)}`;
      // console.log("BRUHHH", formattedDOB);
      setEditPersonalData({
        _id: userData?._id || "",
        userName: userData?.userName || "",
        firstName: userData?.firstName || "",
        lastName: userData?.lastName || "",
        dob: formattedDOB || null,
        mobileNumber: userData?.mobileNumber || "",
        emailId: userData?.emailId,
        gender: userData?.gender || "",
      });
    }
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(true);
    const { name, value } = e.target;

    setEditPersonalData({
      ...editPersonalData,
      [name]: value,
    });
  };

  const handlePersonalSubmit = (e) => {
    e.preventDefault();
    let updateUserData = {};

    updateUserData = {
      id: editPersonalData?._id,
      firstName: editPersonalData?.firstName,
      lastName: editPersonalData?.lastName,
      userName: editPersonalData?.userName,
      dob: editPersonalData?.dob,
      mobileNumber: editPersonalData?.mobileNumber,
      emailId: editPersonalData?.emailId,
      gender: editPersonalData?.gender,
      updatedBy: "1",
      roleId: "1",
      // password: "Pixfix@123",
      // createdBy: "1",
      // accessToken: "",
    };
    console.log("YO", updateUserData);
    updateUser(updateUserData)
      .then((response) => {
        if (response?.data?.status == true) {
          toast.success("Updated Successfully!");
          navigate("/users");
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        toast.error(error?.response?.data?.message);
      });
  };

  const handleCancel = (e) => {
    if (hasUnsavedChanges) {
      Swal.fire({
        html:
          '<div style="display: flex; align-items: center;">' +
          '<div style="margin-right: 10px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path> <line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> </div>' +
          "<span>Changes made may not be saved.</span>" +
          "</div>",

        showCancelButton: true,
        background: "black",
        color: "white",
        position: "top-right",
        customClass: "swal-width",
        confirmButtonColor: "#3c3c3c",
        cancelButtonColor: "#E7515A",
        confirmButtonText: "Leave",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          if (e != "/dash") {
            navigate("/users");
          } else {
            navigate("/dash");
          }
        }
      });
    } else {
      if (e != "/dash") {
        navigate("/users");
      } else {
        navigate("/dash");
      }
    }
  };

  // console.log("KKKKK", editPersonalData?.dob);

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing" id="cancel-row">
          <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div class="widget-content widget-content-area br-6">
              {/* -------------- Header Area ----------------- */}
              <div className="row">
                <div class="col-xl-8 col-md-8 col-sm-8 col-8 mb-3">
                  <h3 class="content-header">Edit User</h3>
                </div>
              </div>

              {/* -------------------------- Breadcrumb Area ----------------------------------- */}
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleCancel("/dash")}
                    >
                      <path
                        d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                        fill="#3c3c3c"
                      />
                    </svg>
                  </li>
                  <li class="breadcrumb-item active">
                    <span
                      onClick={(e) => handleCancel(e)}
                      style={{ cursor: "pointer" }}
                    >
                      Users
                    </span>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Edit User</a>
                  </li>
                </ol>
              </nav>

              {/* ----------- Main Content Region --------------- */}
              <div
                class="table-responsive mb-4 mt-4"
                style={{ overflow: "hidden" }}
              >
                <div
                  id="zero-config_wrapper"
                  class="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div className="row">
                    <div
                      id="tableSimple"
                      className="col-lg-12 col-12 layout-spacing"
                    >
                      <div className="statbox widget box box-shadow">
                        <div className="widget-content widget-content-area">
                          {/* <div className="table-responsive"> */}
                          <form>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    First Name
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="firstName"
                                    type="text"
                                    className="form-control input-form"
                                    placeholder="First Name"
                                    value={editPersonalData.firstName}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    Last Name
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="lastName"
                                    type="text"
                                    className="form-control input-form"
                                    placeholder="Last Name"
                                    value={editPersonalData.lastName}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    User Name
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="userName"
                                    type="text"
                                    className="form-control input-form"
                                    placeholder="First Name"
                                    value={editPersonalData.userName}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    DOB
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="dob"
                                    type="date"
                                    className="form-control input-form"
                                    placeholder="DOB"
                                    value={editPersonalData.dob}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    Phone Number
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="mobileNumber"
                                    type="number"
                                    className="form-control input-form"
                                    placeholder="Phone Number"
                                    value={editPersonalData?.mobileNumber}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    Email Address
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="emailId"
                                    type="email"
                                    className="form-control input-form"
                                    placeholder="Email"
                                    value={editPersonalData?.emailId}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    Gender
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    formControlName="gender"
                                    id="gender"
                                    class="form-control input-form"
                                    required
                                    value={editPersonalData.gender}
                                    name="gender"
                                    onChange={(e) => handleInputChange(e)}
                                  >
                                    <option value="" disabled selected>
                                      -- select an option --
                                    </option>
                                    <option>female</option>
                                    <option>male</option>
                                    <option>others</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="btn submit-btn mr-2"
                              onClick={(e) => handlePersonalSubmit(e)}
                            >
                              Submit
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger mr-2"
                              onClick={(e) => handleCancel(e)}
                            >
                              Cancel
                            </button>
                          </form>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Tabs id="controlled-tab-example" className="tab-switch mb-3"> */}
                  {/* <Tab eventKey="personalDetail" title="Personal Details">
                      
                    </Tab> */}
                  {/* <Tab eventKey="contactDetail" title="Contact Details">
                      <div className="row">
                        <div
                          id="tableSimple"
                          className="col-lg-12 col-12 layout-spacing"
                        >
                          <div className="statbox widget box box-shadow">
                            <div className="widget-content widget-content-area">
                              {/* <div className="table-responsive"> 
                              <form>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-4">
                                      <label
                                        className="create-update-labels"
                                        htmlFor=""
                                      >
                                        Phone Number
                                        <span
                                          className="requireddot"
                                          style={{ color: "red" }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <input
                                        name="mobileNumber"
                                        type="number"
                                        className="form-control input-form"
                                        placeholder="Phone Number"
                                        value={editContactData?.mobile_number}
                                        //   onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group mb-4">
                                      <label
                                        className="create-update-labels"
                                        htmlFor=""
                                      >
                                        Email Address
                                        <span
                                          className="requireddot"
                                          style={{ color: "red" }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <input
                                        name="emailId"
                                        type="email"
                                        className="form-control input-form"
                                        placeholder="Email"
                                        value={editContactData?.email_id}
                                        //   onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn submit-btn mr-2"
                                  // onClick={(e) => {
                                  //   e.preventDefault();
                                  //   handleTabSwitch("preview");
                                  // }}
                                >
                                  Submit
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger mr-2"
                                  //   onClick={(e) => handleCancel(e)}
                                >
                                  Cancel
                                </button>
                              </form>
                              {/* </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUsers;
