import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Sidebar } from "./Sidebar";

import LoginPage from "./Pages/Loginpage/LoginPage";
import Login from "./Pages/Loginpage/Login";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import PasswordRecovery from "./Pages/Loginpage/PasswordRecovery/PasswordRecovery";
import Streams from "./Pages/Streams/Streams";
import CreateStreams from "./Pages/Streams/CreateStreams";
import VoD from "./Pages/VoD/VoD";
import SMTP from "./Pages/Settings/SMTP/SMTP";
import License from "./Pages/Settings/License/License";
import ConfigEditor from "./Pages/Settings/ConfigEditor/ConfigEditor";
import Users from "./Pages/Users/Users";
import EditUsers from "./Pages/Users/EditUsers";
import EditStreams from "./Pages/Streams/EditStreams";
import CreateSmtp from "./Pages/Settings/SMTP/CreateSmtp";
import EditSmtp from "./Pages/Settings/SMTP/EditSmtp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./Pages/Settings/Notification/Notification";
import CreateNotification from "./Pages/Settings/Notification/CreateNotification";
import EditNotification from "./Pages/Settings/Notification/EditNotification";
import LoadingPage from "./Pages/LoadingPage";

const Main = () => {
  const navigate = useNavigate();
  const [loginsuccess, setLoginsuccess] = useState(false);

  let key;
  useEffect(() => {
    key = localStorage.getItem("isAuthenticated");
    // console.log("KEY", key);
  }, []);

  useEffect(() => {
    if (key != 1) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="main-container" id="container">
        {loginsuccess == false && <Navbar setLoginsuccess={setLoginsuccess} />}
        {loginsuccess == false && <Sidebar setLoginsuccess={setLoginsuccess} />}
        {/* <div className="main-container" id="container"> */}
        <Routes>
          <Route
            path="/"
            exact
            element={<LoginPage setLoginsuccess={setLoginsuccess} />}
          ></Route>
          <Route path="/dash" element={<Dashboard />}></Route>
          <Route path="/streams" element={<Streams />}></Route>
          <Route path="/createstream" element={<CreateStreams />}></Route>
          <Route path="/vod" element={<VoD />}></Route>
          <Route path="/smtp" element={<SMTP />}></Route>
          <Route path="/license" element={<License />}></Route>
          <Route path="/configeditor" element={<ConfigEditor />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/editusers" element={<EditUsers />}></Route>
          <Route path="/editstream" element={<EditStreams />}></Route>
          <Route path="/smtp" element={<SMTP />}></Route>
          <Route path="/createsmtp" element={<CreateSmtp />}></Route>
          <Route path="/editsmtp" element={<EditSmtp />}></Route>
          <Route path="/notification" element={<Notification />}></Route>
          <Route
            path="/createNotification"
            element={<CreateNotification />}
          ></Route>
          <Route
            path="/editnotification"
            element={<EditNotification />}
          ></Route>
          {/* <Route path="/loading" element={<LoadingPage />}></Route> */}
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/passwordrecovery"
            element={<PasswordRecovery setLoginsuccess={setLoginsuccess} />}
          ></Route>
        </Routes>
      </div>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        closeButton={false}
      /> */}
    </>
    // </div>
  );
};

export default Main;
