import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { getAllSmtp, deleteSmtpApi } from "../../../../Service/SMTP";
import { getAllUsers } from "../../../../Service/getAllUsers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { userDataSelector } from "../../../../Redux/Login/Selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const SMTP = ({ LOGIN_USER_DATA }) => {
  const userID = LOGIN_USER_DATA?.data?.data[0]?._id;
  const userName = LOGIN_USER_DATA?.data?.data[0]?.userName;
  // console.log("USERID", userID);
  const navigate = useNavigate();
  // const [userId, setUserId] = useState();

  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [iconClicked, setIconClicked] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const handleSetDataPerPage = (data) => {
    setItemsPerPage(data);
  };
  const [smtpList, setSmtpList] = useState();
  const [searchData, setSearchData] = useState();

  // useEffect(() => {
  //   getAllUsers()
  //     .then((response) => {
  //       setUserId(response?.data?.data[0]?._id);
  //       // console.log("XXX", JSON.stringify(response?.data?.data[0]?._id));
  //       // console.log(userId);
  //     })
  //     .catch((error) => {
  //       console.log(JSON.stringify(error));
  //     });
  // }, []);

  useEffect(() => {
    getAllSmtp(userName)
      .then((response) => {
        console.log("SMTP", JSON.stringify(response));
        setSmtpList(response?.data?.data);
        setSearchData(response?.data?.data);
      })
      .catch((error) => {
        console.log("ERROR SMTP", error);
      });
  }, []);

  const handleSort = (columnName) => {
    let direction = "asc";
    if (sortedColumn === columnName && sortDirection === "asc") {
      direction = "desc";
    }
    setSortDirection(direction);
    setSortedColumn(columnName);

    // const sortedData = [...caseList].sort((a, b) => {
    //   const valueA = a && a[columnName];
    //   const valueB = b && b[columnName];

    //   // Check if valueA and valueB are defined
    //   if (valueA === undefined || valueB === undefined) {
    //     return 0; // or handle this case as you see fit
    //   }

    //   const isNumber = !isNaN(valueA) && !isNaN(valueB);

    //   if (direction === "asc") {
    //     if (isNumber) {
    //       return parseFloat(valueA) - parseFloat(valueB);
    //     } else {
    //       return valueA.localeCompare(valueB);
    //     }
    //   } else {
    //     if (isNumber) {
    //       return parseFloat(valueB) - parseFloat(valueA);
    //     } else {
    //       return valueB.localeCompare(valueA);
    //     }
    //   }
    // });

    // setCaselist(sortedData);
  };

  const endOffset = itemOffset + itemsPerPage;

  const currentItems =
    smtpList != null &&
    smtpList != undefined &&
    smtpList?.length != null &&
    smtpList?.length != undefined &&
    smtpList?.length > 0 &&
    smtpList?.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(smtpList?.length / itemsPerPage);
  useEffect(() => {
    pageCount = Math.ceil(smtpList?.length / itemsPerPage);
  }, [smtpList]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % smtpList?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const searchFunction = (data) => {
    var lowSearch = data.toLowerCase();
    let filteredData = searchData?.filter((wine) =>
      Object.values(wine).some((val) =>
        String(val).toLowerCase().includes(lowSearch)
      )
    );
    setSmtpList(filteredData);
    setItemOffset(0);
  };

  const handleDelete = (item) => {
    let deleteItem = {};
    deleteItem = {
      id: item?._id,
      userId: userID,
    };
    console.log("JSJSJSJ", JSON.stringify(item));
    if (item && item?._id) {
      Swal.fire({
        html:
          '<div style="display: flex; align-items: center;">' +
          '<div style="margin-right: 10px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path> <line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> </div>' +
          "<span>Are you sure you want to delete?</span>" +
          "</div>",
        showCancelButton: true,
        background: "black",
        color: "white",
        position: "top-right",
        customClass: "swal-width",
        confirmButtonColor: "#3c3c3c",
        cancelButtonColor: "#E7515A",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSmtpApi(deleteItem)
            .then((response) => {
              if (response?.data?.status === true) {
                toast.success("Deleted Successfully!");
                window.location.reload();
              }
            })
            .catch((error) => {
              console.error("Error deleting :", error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while deleting the item!",
                background: "rgb(15 1 35)",
              });
            });
        }
      });
    } else {
      // Handle case where 'a' or 'a.id' is undefined
      console.error("Invalid parameter 'a' or 'a.id' is undefined.");
      // Optionally show an error message to the user
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid parameter or 'id' is undefined.",
        background: "rgb(15 1 35)",
      });
    }
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing" id="cancel-row">
          <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div
              class="widget-content widget-content-area br-6"
              // style={{ backgroundColor: "#FBF5FF" }}
            >
              {/* -------------- Header Area ----------------- */}
              <div className="row">
                <div class="col-xl-8 col-md-8 col-sm-8 col-8 mb-3">
                  <h3 class="content-header">SMTP</h3>
                </div>
                <div class="col-xl-3 col-md-3 col-sm-3 col-3 mb-3"></div>
                <div class="col-xl-1 col-md-1 col-sm-1 col-1 mb-3">
                  {smtpList?.length == 0 ? (
                    <span title="Create SMTP">
                      <a
                        type="button"
                        style={{ backgroundColor: "#3c3c3c" }}
                        className="btn btn-custom mb-2 mr-2 rounded-circle"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                          onClick={() => navigate("/createsmtp")}
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>

              {/* -------------------------- Breadcrumb Area ----------------------------------- */}
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dash">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                          fill="#3c3c3c"
                        />
                      </svg>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Settings - SMTP</a>
                  </li>
                </ol>
              </nav>

              {/* -------------------- Main Content Area ---------------------- */}

              <div
                class="table-responsive mb-4 mt-4"
                style={{ overflowX: "scroll" }}
              >
                <div
                  id="zero-config_wrapper"
                  class="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div class="tab-content mb-4" id="border-tabsContent">
                    <div
                      class="tab-pane fade show active"
                      id="border-home"
                      role="tabpanel"
                      aria-labelledby="border-home-tab"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-6 d-flex">
                          <div
                            class="dataTables_length"
                            id="zero-config_length"
                          >
                            <label className="select-search-labels">
                              Results :{" "}
                              <select
                                name="zero-config_length"
                                aria-controls="zero-config"
                                class="form-control select-table-size"
                                onChange={(e) =>
                                  handleSetDataPerPage(e.target.value)
                                }
                              >
                                <option value="7">7</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex d-md-block">
                          <div
                            id="zero-config_filter"
                            class="dataTables_filter"
                          >
                            <label className="select-search-labels">
                              Search:{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-search"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                              <input
                                type="search"
                                class="form-control select-table-size"
                                placeholder="Search..."
                                aria-controls="zero-config"
                                onChange={(e) => searchFunction(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-12">
                          <table
                            id="zero-config"
                            class="table table-hover dataTable"
                            style={{
                              // width: "100%",
                              // role: "grid",
                              // ariaDescribedby: "zero-config_info",
                              border: " 1px solid #cccccc",
                              borderCollapse: " collapse",
                            }}
                          >
                            <thead className="table-theader">
                              <tr role="row">
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Server
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Host
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Port
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Username
                                </th>
                                <th
                                  className={`${
                                    iconClicked ? "icon-clicked" : ""
                                  } table-thead-tr`}
                                  onClick={() => handleSort("case_num")}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.length > 0 &&
                                currentItems?.map((item) => {
                                  return (
                                    <tr role="row">
                                      <td className="table-tbody-tr">
                                        {item?.server_name}
                                      </td>
                                      <td className="table-tbody-tr">
                                        {item?.host}
                                      </td>
                                      <td className="table-tbody-tr">
                                        {item?.port}
                                      </td>
                                      <td className="table-tbody-tr">
                                        {item?.user_name}
                                      </td>
                                      <td className="table-tbody-tr">
                                        <span title="Edit SMTP">
                                          <svg
                                            _ngcontent-orq-c74=""
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-edit"
                                            style={{
                                              marginRight: "5px",
                                              cursor: "pointer",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={() =>
                                              navigate("/editsmtp", {
                                                state: {
                                                  item: item,
                                                },
                                              })
                                            }
                                          >
                                            <path
                                              _ngcontent-orq-c74=""
                                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                            ></path>
                                            <path
                                              _ngcontent-orq-c74=""
                                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                            ></path>
                                          </svg>
                                        </span>
                                        <span title="Delete SMTP">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trash-2"
                                            title="DeleteSmtp"
                                            style={{
                                              marginRight: "5px",
                                              cursor: "pointer",
                                              stroke: "#3c3c3c",
                                            }}
                                            onClick={() => handleDelete(item)}
                                          >
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line
                                              x1="10"
                                              y1="11"
                                              x2="10"
                                              y2="17"
                                            ></line>
                                            <line
                                              x1="14"
                                              y1="11"
                                              x2="14"
                                              y2="17"
                                            ></line>
                                          </svg>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            {/* {caseList?.length == 0 && (
                              <paragraph>No matching records found</paragraph>
                            )} */}
                          </div>
                          {/* <Items currentItems={currentItems} /> */}

                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={15}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-7">
                          <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="zero-config_paginate"
                          ></div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LOGIN_USER_DATA: userDataSelector,
});

export default connect(mapStateToProps)(SMTP);
