import ACTION_TYPES from "./Action.Types";

const { LOGIN_PAGE_START, LOGIN_PAGE_SUCCESS, LOGIN_PAGE_FAILURE, LOGOUT } =
  ACTION_TYPES;

const initialState = {
  loginUserData: {},
  loginCredentials: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_PAGE_START:
      console.log("REDUCER", JSON.stringify(payload));
      return {
        ...state,
        loginCredentials: payload,
        loginUserData: {},
      };

    case LOGIN_PAGE_SUCCESS:
      localStorage.setItem("isAuthenticated", 1);
      localStorage.setItem("accessToken", payload?.data?.data[1].access_token);
      return {
        ...state,
        loginUserData: payload,
      };

    case LOGIN_PAGE_FAILURE:
      return {
        ...state,
        loginUserData: {},
      };

    case LOGOUT:
      localStorage.setItem("isAuthenticated", 0);
      localStorage.setItem("accessToken", "");
      // localStorage.setItem("refreshtoken", "");
      // sessionStorage.setItem("keyforroute", 0);

      return {
        ...state,
        loginUserData: {},
        isAuthenticated: false,
        isAuthenticatedForValidation: false,
      };

    default:
      return state;
  }
};
