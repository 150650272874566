import axiosInstance from "../utils/axiosInstance";

export const getAllnotificationDataApi = async (payload) => {
  return await axiosInstance()
    .get("notiRecipient", {
      params: {
        userName: payload,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const createNotificationApi = async (payload) => {
  return await axiosInstance()
    .post("create/notiRecipient", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const updateNotificationApi = async (payload) => {
  return await axiosInstance()
    .put("update/notiRecipient", payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

export const deleteNotificationApi = async (payload) => {
  return await axiosInstance()
    .delete(`delete/notiRecipient/${payload?.id}`, payload?.userName, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};
