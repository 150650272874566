import axios from "axios";
import { serverPath } from "../Constants/Constants";

export const downloadHidKeyApi = async () => {
  return await axios
    .get("hidkey", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
