import { all, put, take, call } from "redux-saga/effects";
import ACTION_TYPES from "./Action.Types";
import * as USER_LOGIC_ACTION from "./Action";
import * as USER_LOGIN_API from "../../Service/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { LOGIN_PAGE_START } = ACTION_TYPES;

function* loginPageSaga(payload) {
  console.log("SAGA PAYLOAD", JSON.stringify(payload));
  try {
    const { data } = yield call(USER_LOGIN_API.loginUserData, payload);

    if (data?.status == true && data?.data?.length > 0) {
      // toast.success("Logged in Successfully!");
      // console.log(payload?.initialLogout);
      // console.log("MAAMS", payload?.initialLogout);
      if (payload?.initialLogin == "true") {
        window.location.href = "/streams";
      }

      // console.log("success");
    } else {
      console.log("error", data);
      toast.error(data?.message);
    }
    // console.log("SAGA", JSON.stringify(data?.data.length));
    yield put(USER_LOGIC_ACTION.loginPageSuccess({ data }));
    // window.location.href = "/streams";
  } catch ({ error }) {
    // console.log("ERROR SAGA", JSON.stringify(error));
    yield put(USER_LOGIC_ACTION.loginPageFailure({ error }));
  }
}

function* loginPageWatcher() {
  while (true) {
    const { payload } = yield take(LOGIN_PAGE_START);
    yield call(loginPageSaga, payload);
  }
}

export default function* () {
  yield all([loginPageWatcher()]);
}
