import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { userDataSelector } from "../../../../Redux/Login/Selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { updateNotificationApi } from "../../../../Service/NotificationRecipients";
import { toast } from "react-toastify";

const EditNotification = ({ LOGIN_USER_DATA }) => {
  const userID = LOGIN_USER_DATA?.data?.data[0]?._id;
  const userName = LOGIN_USER_DATA?.data?.data[0]?.userName;
  const navigate = useNavigate();
  const location = useLocation();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [updateNotification, setUpdateNotification] = useState({
    emailId: "",
  });

  const notificationData = location?.state?.item;
  // console.log(JSON.stringify(notificationData));

  useEffect(() => {
    setUpdateNotification({
      emailId: notificationData?.emailId,
    });
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;

    setHasUnsavedChanges(true);
    setUpdateNotification({ ...updateNotification, [e.target.name]: value });
  };

  const handleUpdateNotification = (e) => {
    e.preventDefault();
    let updatedData = {};

    updatedData = {
      id: notificationData?._id,
      emailId: updateNotification?.emailId,
      updatedBy: userID,
      userName: userName,
    };
    // console.log("KKKK", updatedData);

    updateNotificationApi(updatedData)
      .then((response) => {
        // toast.success("Updated successfully.")
        if (response?.data?.status == true) {
          toast.success("Updated successfully.");
          navigate("/notification");
        }
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  };

  const handleCancel = (e) => {
    if (hasUnsavedChanges) {
      Swal.fire({
        html:
          '<div style="display: flex; align-items: center;">' +
          '<div style="margin-right: 10px"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path> <line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> </div>' +
          "<span>Changes made may not be saved.</span>" +
          "</div>",

        showCancelButton: true,
        background: "black",
        color: "white",
        position: "top-right",
        customClass: "swal-width",
        confirmButtonColor: "#3c3c3c",
        cancelButtonColor: "#E7515A",
        confirmButtonText: "Leave",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          if (e != "/dash") {
            navigate("/notification");
          } else {
            navigate("/dash");
          }
        }
      });
    } else {
      if (e != "/dash") {
        navigate("/notification");
      } else {
        navigate("/dash");
      }
    }
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
        <div class="row layout-top-spacing" id="cancel-row">
          <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div class="widget-content widget-content-area br-6">
              {/* ---------- Header Region --------------- */}
              <div className="row">
                <div class="col-xl-8 col-md-8 col-sm-8 col-8 mb-3">
                  <h3 class="content-header">Update Notification</h3>
                </div>
              </div>

              {/* --------------- Breadcrumb Region ------------- */}
              <nav className="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleCancel("/dash")}
                    >
                      <path
                        d="M9.75 21.75V16.492C9.75 16.125 10.125 15.75 10.5 15.75H13.5C13.875 15.75 14.25 16.125 14.25 16.5V21.75C14.25 22.1642 14.5858 22.5 15 22.5H21C21.4142 22.5 21.75 22.1642 21.75 21.75V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197L19.5 8.68934V3.75C19.5 3.33579 19.1642 3 18.75 3H17.25C16.8358 3 16.5 3.33579 16.5 3.75V5.68934L12.5303 1.71967C12.2374 1.42678 11.7626 1.42678 11.4697 1.71967L2.46967 10.7197C2.32902 10.8603 2.25 11.0511 2.25 11.25V21.75C2.25 22.1642 2.58579 22.5 3 22.5H9C9.41421 22.5 9.75 22.1642 9.75 21.75Z"
                        fill="#3c3c3c"
                      />
                    </svg>
                  </li>
                  <li class="breadcrumb-item active">
                    <span
                      onClick={(e) => handleCancel(e)}
                      style={{ cursor: "pointer" }}
                    >
                      Settings - Notifications
                    </span>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0);">Update Notifications</a>
                  </li>
                  {/* <li class="breadcrumb-item active" aria-current="page">
                      <span>UI Kit</span>
                    </li> */}
                </ol>
              </nav>

              {/* --------------- Main COntent -------------------- */}

              <div
                class="table-responsive mb-4 mt-4"
                style={{ overflow: "hidden" }}
              >
                <div
                  id="zero-config_wrapper"
                  class="dataTables_wrapper container-fluid dt-bootstrap4"
                >
                  <div className="row">
                    <div
                      id="tableSimple"
                      className="col-lg-12 col-12 layout-spacing"
                    >
                      <div className="statbox widget box box-shadow">
                        <div className="widget-content widget-content-area">
                          {/* <div className="table-responsive"> */}
                          <form>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group mb-4">
                                  <label
                                    className="create-update-labels"
                                    htmlFor=""
                                  >
                                    Email ID
                                    <span
                                      className="requireddot"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    name="emailId"
                                    type="emailId"
                                    className="form-control input-form"
                                    placeholder="Email Id"
                                    value={updateNotification?.emailId}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="btn submit-btn mr-2"
                              onClick={(e) => handleUpdateNotification(e)}
                            >
                              Submit
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger mr-2"
                              onClick={(e) => handleCancel(e)}
                            >
                              Cancel
                            </button>
                          </form>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LOGIN_USER_DATA: userDataSelector,
});

export default connect(mapStateToProps)(EditNotification);
